import React from 'react';
import Fade from 'react-reveal/Fade';
import { useTranslation } from "react-i18next";
const EMA = () => {
    const {t, i18n } = useTranslation();
    return (
        <Fade right>
            <div className="bg-white flex w-full  pt-8 lg:pt-0 lg:pl-14 p-10 mt-5 lg:mt-0 bg-gray-50">
                <div className="w-full flex flex-col lg:flex-row">
                    <div className="w-full flex flex-col lg:flex-row justify-center">
                    <div className="w-full flex justify-center">
                                <div className="flex lg:text-4xl lg:font-bold text-gray-800 self-center lg:pb-10 text-3xl font-bold mb-8 font"> {t('mt1')} </div>
                        </div>
                        <div className="w-full flex justify-center">
                                <div className="flex self-center lg:w-96 lg:text-left text-gray-700 font pt-10 pb-10">
                               {t('mb1')}

                                </div>
                        </div>

                    </div>

                </div>
            </div>
        </Fade>
    );
};

export default EMA;
