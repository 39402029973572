import React from 'react';
import Utilities from '../utilities/MasterHeader'
import Footer from '../components/Footer'
import Why from '../components/Why';
import Centre from '../components/Centre_fiberlab';
import TestFiberlab from '../components/Test_fiberlab';
import Calibration from '../components/Calibration';
import OtherServices from '../components/Other_services';
const Servicios = () => {
    return (
        <div>
            <Utilities />
            <Why />
            <TestFiberlab />
            <Calibration />
            <Centre />
            <OtherServices />
            <Footer />
        </div>
    );
};

export default Servicios;
