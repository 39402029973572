import React from 'react';
import Fade from 'react-reveal/Fade';
import Cables from '../assets/images/OurPeople.PNG'
import { useTranslation } from "react-i18next";
const Ourpeople = () => {
    const {t, i18n } = useTranslation();
    return (
        <Fade right>
            <div className="bg-white  lg:h-auto flex w-full pt-8 lg:pt-0 lg:pl-14 p-10 mt-5 lg:mt-0 lg:pt-0 lg:pb-0">
                <div className="w-full flex flex-col lg:flex-row lg:pb-10">

                    <div className="w-full lg:h-96 flex flex-col justify-center  self-center">
                        <div className="flex lg:text-4xl lg:font-bold text-gray-800 self-center lg:pb-0 text-3xl font-bold mb-10 mt-8 font ">{t('ft1')} </div>
                        <div className="flex self-center lg:w-96 lg:text-center text-gray-700 font">
                        {t('fb1')}

                        </div>
                    </div>
                    <div className=" lg:visible w-full self-center lg:p-0 mt-10">
                        <img src={Cables} alt=""></img>
                    </div>

                </div>
            </div>
        </Fade>
    );
};

export default Ourpeople;
