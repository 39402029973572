import React from 'react';
import Image from '../assets/images/image_uno.PNG'
import Image_two from '../assets/images/image_dos.PNG'
import Image_tree from '../assets/images/image_tres.PNG'
import { useTranslation } from "react-i18next";
const Description = () => {
    const {t, i18n } = useTranslation();
    return (
        <div className="w-full lg:h-96">
            <div className="flex flex-col lg:flex-row">
                <div className="w-full" style={{backgroundColor: "#F0EFEF"}}>
                    <div className="flex flex-col justify-center"  >
                        <div className="flex justify-center pt-8">
                            <img  src={Image} width="150" alt=""></img>
                        </div>

                        <div className="text-2xl font-normal mb-5 subtitulo font" >{t("dt1")} </div>
                        <div className="pl-16 pr-16">
                                <div className="text-sm mb-3 font-sans text-center  parrafo font">{t("db1")} <span className="font-semibold">{t("db2")}</span> {t("db3")}<span className="font-semibold">The International Laboratory
                                    Accreditation Cooperation (ilac).</span>
                                </div>
                            </div>
                            <div className="flex justify-center mt-7">
                                  <a href="/empresa#quienessomos" className=" w-24 ver_mas text-white rounded-full p-1 font">
                                    {t("db4")}
                                  </a>
                            </div>

                    </div>
                </div>
             <div className="w-full" style={{backgroundColor: "#F0EFEF"}}>
                <div className="flex flex-col">
                    <div className="flex justify-center pt-6">
                        <img  src={Image_two} width="150" alt=""></img>
                    </div>
                    <div className="text-2xl font-normal mb-5 subtitulo font" > {t("dt2")}</div>
                    <div className="pl-16 pr-16">
                    <div className="text-sm mb-3 text-center parrafo font"> {t("db2A")}<span className="font-semibold"> {t("db2B")}</span></div>
                    </div>
                    <div className="flex justify-center mt-11">
                                <a href="/acreditaciones" className=" w-24 ver_mas text-white rounded-full p-1 font">
                                {t("db2C")}
                                </a>
                    </div>
                </div>
            </div>
             <div className="w-full pb-10" style={{backgroundColor: "#F0EFEF"}}>
                <div className="flex flex-col">
                <div className="flex justify-center pt-10">
                    <img  src={Image_tree} width="150" alt=""></img>
                </div>
                    <div className="text-2xl font-normal mb-5 subtitulo font" >{t("dt3")}</div>
                    <div className="pl-16 pr-16">
                    <div className="text-sm mb-3 text-center parrafo font">{t("db3A")} <span className="font-semibold">{t("db3B")} </span> {t("db3C")} </div>

                    </div>

                    <div className="flex justify-center mt-1 ">
                                <a href="/laboratorio" className=" w-24 ver_mas text-white rounded-full p-1 font">
                                {t("db3D")} 
                                </a>
                    </div>
                </div>
            </div>
            </div>
        </div>
    );
};

export default Description;
