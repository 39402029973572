import React from "react";
import { useTranslation } from "react-i18next";

/* import { Icon } from 'react-icons-kit'
import {bookmarks} from 'react-icons-kit/icomoon/bookmarks'
 */


const SubHeader = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="subheader h-10 sm:hidden xs:hidden md:hidden">
      <ul className="sm:hidden xs:hidden md:hidden lg:visible w-full flex flex-row text-white justify-center lg:pl-28 lg:pr-28">
        <a
          href="/"
          className="flex hover:bg-gray-500 hover:text-gray-100 w-full h-10"
        >
          <li className="w-full self-center font">{t("Inicio")}</li>
        </a>
      {/*   <a
          href="/orden"
          className="flex hover:bg-gray-100 hover:text-gray-500 w-full h-10"
        >
          <li className="w-full self-center font">Orden de servicio</li>
        </a> */}
        <a
          href="/empresa"
          className="flex hover:bg-gray-500 hover:text-gray-100 w-full h-10"
        >
          <li className="w-full self-center font">{t("Empresa")}</li>
        </a>
        <a
          href="/acreditaciones"
          className="flex hover:bg-gray-400 hover:text-gray-200 w-full h-10"
        >
          <li className="w-full self-center font">{t("Acreditaciones")}</li>
        </a>
        <a
          href="/laboratorio"
          className="flex hover:bg-gray-300 hover:text-gray-500 w-full h-10"
        >
          <li className="w-full self-center font">{t("Laboratorios")}</li>
        </a>
        <a
          href="/servicios"
          className="flex hover:bg-gray-200 hover:text-gray-500 w-full h-10"
        >
          <li className="w-full self-center font">{t("Servicios")}</li>
        </a>
        <a
          href="/media"
          className="flex hover:bg-gray-200 hover:text-gray-500 w-full h-10"
        >
          <li className="w-full self-center font">{t("Media")}</li>
        </a>
        <a
          href="/wiki"
          className="flex hover:bg-gray-100 hover:text-gray-500 w-full h-10"
        >
          <li className="w-full self-center font">{t("Wiki")}</li>
        </a>
        <a
          href="/blog"
          className="flex hover:bg-gray-100 hover:text-gray-500 w-full h-10"
        >
          <li className="w-full self-center font">Blog</li>
        </a>
       
      </ul>
    </div>
  );
};

export default SubHeader;
