import React from 'react';
import Fade from 'react-reveal/Fade';
import Cables from '../assets/images/wiki.png'
import { useTranslation } from "react-i18next";
const Videos = () => {
    const {t, i18n } = useTranslation();
    return (
        <Fade right>
            <div className="bg-white flex w-full lg:HowEmpresaSize pt-8 lg:pt-0 lg:pl-14 p-10 mt-5 lg:mt-0">
                <div className="w-full flex flex-col lg:flex-row">
                    <div className="flex flex-col lg:mt-10 lg:w-full ">
                        <div className="flex lg:text-4xl lg:font-semibold text-gray-800 self-center lg:self-start lg:pl-0 lg:pb-5 text-2xl font-bold mt-0 font mb-8 lg:mb-0">{t('xt1')} </div>
                        <iframe allow="autoplay" className="lg:w-full w-auto" width="600" height="500" src="https://www.youtube.com/embed/uWKi5lYKnDc?modestbranding=1&amp;fs=0&amp;rel=0&amp;hd=1&amp;disablekb=0&amp;showinfo=0&amp;iv_load_policy=0&amp;enablejsapi=1&amp;autoplay=0&amp;loop=0&amp;controls=0" frameborder="0" allow="autoplay" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </Fade>
    );
};

export default Videos;
