import React from 'react';
import Utilities from '../utilities/MasterHeader'
import Ver from '../components/verBlog'
import Footer from '../components/Footer'
const Descripcion = () => {
    return (
        <div>
            <Utilities />
          
            <Ver />
            <Footer />
        </div>
    );
};

export default Descripcion;