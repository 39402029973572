import React, {useState, useEffect} from 'react';
import Logo from '../assets/images/logo.png'
import Mexico from '../assets/images/Mexico.svg'
import Search from '@material-ui/icons/Search';
import MenuIcon from '@material-ui/icons/Menu'
import { slide as Menu } from 'react-burger-menu'
import { useTranslation } from "react-i18next";
import Cookies from 'universal-cookie';

const Header = () => {
    
    const cookies = new Cookies();
    const {t, i18n } = useTranslation();
    const [language, setLanguage] = useState("es");
    const showSettings = (event) =>  {
        event.preventDefault();
    }
    const handleLangChange = (evt) => {
        const lang = evt.target.value;
        console.log(lang);
        setLanguage(lang);
        i18n.changeLanguage(lang);
        cookies.set('lang', lang);
        console.log(cookies.get('lang'));
        window.location.replace('');
      };

    useEffect(() => {
        const lang = cookies.get('lang');
        if(lang){
            setLanguage(lang);
            i18n.changeLanguage(lang);
        }
    }, []);

    return (
        <div>
            <div id="outer-container" className=" lg:hidden">
                <Menu customBurgerIcon={ <MenuIcon className="text-white boton p-1" /> }>
                    <ul className="w-full flex flex-col text-white justify-center">
                        <a href="/" className="flex hover:bg-gray-500 hover:text-gray-100 w-full h-10">
                            <li className="w-full self-center font">{t("Inicio")}</li>
                        </a>
                        <a href="/empresa" className="flex hover:bg-gray-500 hover:text-gray-100 w-full h-10">
                            <li className="w-full self-center font">{t("Empresa")}</li>
                        </a>
                        <a href="/acreditaciones" className="flex hover:bg-gray-400 hover:text-gray-200 w-full h-10">
                            <li className="w-full self-center font">{t("Acreditaciones")}</li>
                        </a>
                        <a href="/laboratorio" className="flex hover:bg-gray-300 hover:text-gray-500 w-full h-10">
                            <li className="w-full self-center font">{t("Laboratorios")}</li>
                        </a>
                        <a href="/servicios" className="flex hover:bg-gray-200 hover:text-gray-500 w-full h-10">
                            <li className="w-full self-center font">{t("Servicios")}</li>
                        </a>
                        <a href="/media" className="flex hover:bg-gray-200 hover:text-gray-500 w-full h-10">
                            <li className="w-full self-center font">Media</li>
                        </a>
                        <a href="/wiki" className="flex hover:bg-gray-100 hover:text-gray-500 w-full h-10">
                            <li className="w-full self-center font">Wiki</li>
                        </a>
                        <a href="/blog" className="flex hover:bg-gray-100 hover:text-gray-500 w-full h-10">
                            <li className="w-full self-center font">Blog</li>
                        </a>
                        <a href="/contacto" className="flex hover:bg-gray-100 hover:text-gray-500 w-full h-10">
                          <li className="w-full self-center font">{t("at1")}</li>
                        </a>
                       
                    </ul>
                </Menu>
            </div>

            <div className="hidden lg:flex flex-col lg:flex-row black-header w-full lg:h-24 h-32">
                <div className="_left_"></div>
                <div className="_center_ flex flex-row">
                    
                    <div className="_left_interno_  self-center ">
                        <a href="/">
                            <img src={Logo} alt="Fiberlab" className="logo"></img>
                        </a>
                    </div>
                    <div className="_center_ flex flex-row self-center w-full p-3">
                        <div className="relative w-full">
                            {/* <Search className=" text-blue-500 pointer-events-none w-8 h-8 absolute top-1/2 transform -translate-y-1/2 right-3" />
                            <input type="text" name="buscar" className="pl-5 p-2 w-full rounded-full"/> */}
                        </div>
                    </div>
                    <div className="_right_interno_ self-center">
                        <div className="flex flex-row justify-center">
                            <div className=" pr-3 underline text-white font"><a href="https://clientes.laboratoriodefibraoptica.com/" target="_blank">{t('BtnWeb')} </a>
                            <br/><a href="/contacto">{t('at1')} </a></div>
                            <select onChange={handleLangChange} className='px-3 py-1 w-auto text-gray-500' value={language}>
                            <option value="es">Español</option>
                            <option value="en">English</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div  className="_right_"></div>
            </div>
           
                            
                           
                        
          

            <div className="lg:hidden flex flex-col justify-center w-full h-20 mb-20 ">
                <div className="flex flex-col black-header xs:h-96 xs:pb-10 xs:pt-28">
                    <div className="mt-10 _left_interno_small_  self-center ">
                    
                        <a href="/">
                            <img src={Logo} alt="Fiberlab" className="logo_small"></img>
                        </a>
                        <div className='' >
                        <select  onChange={handleLangChange}  className='mt-2 px-3 py-1 w-auto text-gray-500' value={language}>
                            <option value="es"> Español</option>
                            <option value="en"> English</option>
                            </select>
                        </div>
                    </div>
                    <div className="_center_ flex flex-row self-center w-full p-3">
                        <div className="relative w-full xs:hidden">
                           {/*  <Search className=" text-blue-500 pointer-events-none w-8 h-8 absolute top-1/2 transform -translate-y-1/2 right-3" />
                            <input type="text" name="buscar" className="pl-5 p-2 w-full rounded-full"/> */}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Header;
