import React from 'react';
import PhoneIcon from '@material-ui/icons/Phone';
import Pulse from 'react-reveal/Pulse';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import YoutubeIcon from '@material-ui/icons/YouTube';
import WhatsappIcon from '@material-ui/icons/WhatsApp';
import Cables from '../assets/images/footer.png'
import { useTranslation } from "react-i18next";

const Footer = () => {
    const {t,i18n} = useTranslation();
    return (
        <div className="w-full bg-black footer pt-8 lg:pl-14" id="footer">
          <div className="flex lg:flex-row flex-col ">
                <div className="w-full pl-10 pr-10 lg:pt-10 lg:pl-36 flex flex-col lg:justify-start justify-center ">
                    <div className="self-center lg:self-start text-white lg:text-3xl text-2xl font-semibold pb-14 font">

                    </div>
                    <div className="self-center lg:self-start text-xl subtitulo-blue text-white font-sans font-semibold font">
                        Fiberlab S. de R.L. de C.V.
                    </div>
                    <div className="self-center lg:self-start text-white">
                        <div className="flex flex-row">
                            <PhoneIcon  className="self-center"/><span className="text-3xl font-bold self-center pl-2 font xs:text-xl"><Pulse>800 8908 490</Pulse></span>
                        </div>
                    </div>
                    <div className="subtitulo-blue self-center lg:self-start text-white text-xl font-semibold font">
                        contacto@fiberlab.com.mx
                    </div>

                    <div className="self-center lg:self-start text-gray-300 text-base pt-1 font">
                    EUROPARK II, Carretera Estatal No. 431 ,
                    </div>
                    <div className="self-center lg:self-start text-gray-300 text-base font">
                    1.933 Int 26A, C.P. 76246, El Marques, Qro
                    </div>
                    <div className="self-center lg:self-start text-white">
                        <a target="_blank" href="static/media/POLÍTICA_DE_CONFIDENCIALIDAD.pdf">
                            <b>{t("pp1")}</b>
                        </a>
                    </div>
                    <div className="self-center lg:self-start text-white">
                        <a target="_blank" href="static/media/POLITICA_DE_IMPARCILIDAD.pdf">
                            <b>{t("pp2")}</b>
                        </a>
                    </div>
                    <div className="self-center lg:self-start text-white">
                        <a target="_blank" href="static/media/POLITICAS_DE_PRIVACIDAD_FIBERLAB.pdf">
                            <b>{t("pp3")}</b>
                        </a>
                    </div>

                    <div className="self-center lg:self-start flex flex-row lg:pb-20">
                        <div className="pt-5 pr-5">
                        <a href="https://www.facebook.com/fiberlabmx/" target="_blank"><FacebookIcon  className="text-white "/></a>
                        </div>
                        <div className="pt-5 pr-5">
                        <a href="https://twitter.com/fiberlab1" target="_blank"><TwitterIcon  className="text-white"/></a>
                        </div>
                        <div className="pt-5 pr-5">
                        <a href="https://www.youtube.com/channel/UCjhlYGahtaBCT4bAQ6nN82A" target="_blank"><YoutubeIcon  className="text-white"/></a>
                        </div>
                        <div className="pt-5 pr-5">
                        <a href="https://api.whatsapp.com/send/?phone=524423094719&text=Hola&app_absent=0" target="_blank"><WhatsappIcon  className="text-white"/></a>
                        </div>
                    </div>
                </div>
                <div className="lg:visible invisible w-0 lg:w-auto">
                    <img src={Cables} alt=""></img>
                </div>
            </div>
        </div>
    );
};

export default Footer;
