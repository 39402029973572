import React from 'react';
import Utilities from '../utilities/MasterHeader'
import Footer from '../components/Footer'
import Laboratory from '../components/Laboratory';
import LaboratoryTest from '../components/Laboratory_test';
import LaboratoryCalibration from '../components/Laboratory_calibration';
import LaboratoryElectronic from '../components/Laboratory_Electronic';
import Devices from '../components/Devices';
const Laboratorio = () => {
    return (
        <div>
            <Utilities />
            <Laboratory />
            <LaboratoryTest />
            <LaboratoryElectronic />
            <LaboratoryCalibration />
          
            <Footer />
        </div>
    );
};

export default Laboratorio;
