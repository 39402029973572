import React from 'react';
import Fade from 'react-reveal/Fade';
import { useTranslation } from "react-i18next";
const Know = () => {
    const {t, i18n } = useTranslation();
    return (
        <Fade bottom>
            <div className="know-background w-full h-60 lg:h-96 p-10 mt-8 lg:mt-0 xs:h-auto">
                <div className="w-full lg:h-96 justify-center flex flex-col lg:flex-row">
                    {/* <div className= "lg:text-center text-white font-sans text-4xl lg:text-7xl lg:font-bold font-bold lg:pb-16 mb-8 self-center font">
                        {t("kt1")} <span className="invisible lg:visible">/</span>
                    </div> */}
                    <div className="justify-center lg:h-96 self-center flex flex-col lg:pl-10 lg:pb-20">
                        <div className="text-4xl lg:text-6xl lg:pb-1 lg:font-bold font">
                        {t("kb1A")}
                        </div>
                        <div className="lg:w-96 lg:text-justify text-white text-xl lg:text-xl font">
                        {t("kb1B")}
                        </div>
                    </div>

                </div>
            </div>
        </Fade>
    );
};

export default Know;
