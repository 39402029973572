import React, { useState, useEffect } from "react";

import Fade from "react-reveal/Fade";
import Estilos from "../Estilos.css";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { useTranslation } from "react-i18next";
import Constantes from "./Constantes";
import Cookies from "universal-cookie";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";

const cookies = new Cookies();

const idioma = cookies.get("lang");
if (idioma == null) {
  cookies.set("lang", "es");
  window.location.replace("");
}

class Banner extends React.Component {
  // Constructor
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      DataisLoaded: false,
    };
  }

  // ComponentDidMount is used to
  // execute the code

  componentDidMount() {
    fetch(`${Constantes.RUTA_API}/obtenerbannerid.php?id=` + idioma)
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          items: json,
          DataisLoaded: true,
        });
      });
  }
  render() {
    const responsive = {
      0: { items: 1 },
      1024: { items: 1 },
    };

    const { DataisLoaded, items } = this.state;
    if (!DataisLoaded)
      return (
        <div>
          <h1> Pleses wait some time.... </h1>{" "}
        </div>
      );

    return (
      <div className="App">
        <div className="mt-5 lg:mt-0">
          <div className="mt-20 lg:mt-10 w-full absolute z-10"></div>
          {/* <AliceCarousel responsive={responsive}
           autoPlayInterval={5000}
           autoPlayDirection="rtl"
           autoPlay={true}
           fadeOutAnimation={true}
           mouseTrackingEnabled={true}
           disableAutoPlayOnAction={true}
           infinite={true}
           disableButtonsControls={true}
           disableDotsControls={false}>

            
             {
                items.map((item) => ( 
                  
                <ol key = { item.id } >
                   
                   <a href={item.link == ""  ? null : item.link}  target="_blank" ><img src={item.img} className="sliderimg"/></a>
                    
                 </ol>
                ))
            }
            

       </AliceCarousel> */}
          <Carousel
            isMaximized={false}
            hasSizeButton={false}
            hasMediaButton={false}
            hasIndexBoard={false}
            autoPlayIntervall={5000}
            hasCaptionsAtMax="top"
            hasDotButtonsAtMax="bottom"
            hasThumbnails={false}
            hasThumbnailsAtMax={false}
            thumbnailWidth={"15%"}
            thumbnailHeight={"15%"}
            shouldMaximizeOnClick={false}
            shouldMinimizeOnClick={false}
            hasDotButtons="bottom"
            activeIcon={
              <span className="icon-text" role="img" aria-label="active">
                ⚫
              </span>
            }
            passiveIcon={
              <span className="icon-text" role="img" aria-label="passive">
                ⚪
              </span>
            }
          >
            {items.map((item) => (
              <a
                href={item.link == "" ? null : item.link}
                target="_blank"
                style={{ height: 500, width: 8000 }}
              >
                <img src={item.img} className="sliderimg" />
              </a>
            ))}
          </Carousel>
        </div>
      </div>
    );
  }
}

export default Banner;
