import React from 'react';
import Fade from 'react-reveal/Fade';
import Cables from '../assets/images/OtherServices.PNG'
import { useTranslation } from "react-i18next";
const OtherServices = () => {
    const {t, i18n } = useTranslation();
    return (
        <Fade right>
            <div className="bg-white flex w-full lg:h-auto  pt-8 lg:pt-14 lg:pb-14 lg:pl-14 p-10 mt-5 lg:mt-0 " >
                <div className="lg:h-auto lg:m-10 w-full flex flex-col lg:flex-row">

                    <div className="w-full lg:h-96 flex flex-col justify-center lg:p-20 ">
                        <div className="flex lg:text-4xl lg:font-bold text-gray-800 lg:pb-10 text-2xl w-full font-bold mb-8 font text-justify lg:text-center">{t('wt1')} </div>
                        <div className="flex self-center lg:text-center text-gray-700 flex-col font  w-full">
                        <div className="font-normal text-justify "><strong>• </strong>{t('wb1')}</div>
                        <div className="font-normal text-justify "><strong>• </strong>{t('wb2')}</div>
                        <div className="font font-normal lg:text-justify text-left lg:text-base"><strong>• </strong>{t('wb3')}</div>
                        <div className="font-normal text-justify "><strong>• </strong>{t('wb4')}</div>
                        <div className="font-normal text-justify "><strong>• </strong>{t('wb5')}</div>
                        <div className="lg:pl-0 ">
                        <div className="font-normal pl-0 text-justify"> <strong>-</strong>{t('wb6')}</div>
                        <div className="font-normal pl-0 text-justify"> <strong>-</strong>{t('wb7')}</div>
                        <div className="font-normal pl-0 text-justify" > <strong>-</strong>{t('wb8')}</div>
                        <div className="font-normal pl-0 text-justify"> <strong>-</strong>{t('wb9')}</div>
                        </div>
                        </div>
                    </div>
                    <div className="lg:visible w-full self-center md:self-center flex justify-center">
                        <img src={Cables} alt="" className="lg:w-96 lg:ml-20"></img>
                    </div>
                </div>
            </div>
        </Fade>
    );
};

export default OtherServices;
