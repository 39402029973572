/*

------------------------------------------------------------------------------------------------
🆁🅼🅽 2022 07 01

------------------------------------------------------------------------------------------------
*/
const Constantes = {
     RUTA_API: "https://laboratoriodefibraoptica.com/api-php-react/", //SERVER  TODO:  cambiar cuando se haga el build*/
 /*   RUTA_API: "http://localhost/fiberlab/api-php-react/",  // TODO:  LOCAL.  */

};
export default Constantes;