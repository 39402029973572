import React from 'react';
import Fade from 'react-reveal/Fade';
import Cables from '../assets/images/wiki.png'
import { useTranslation } from "react-i18next";
const WikiContent = () => {
    const {t, i18n } = useTranslation();
    return (
        <Fade right>
            <div className="bg-white flex w-full lg:HowEmpresaSize pt-8 lg:pt-0 lg:pl-14 p-10 mt-5 lg:mt-0">
                <div className="w-full flex flex-col lg:flex-row">
                    <div className="flex flex-col lg:mt-10 w-full">
                        <div className="flex lg:text-4xl lg:font-semibold text-gray-800 self-center lg:self-start lg:pl-0 lg:pb-0 text-3xl font-bold mt-0 font mb-0 lg:mb-0">{t('yt1')} </div>

                    </div>
                </div>
            </div>
        </Fade>
    );
};

export default WikiContent;
