import React from 'react';
import Utilities from '../utilities/MasterHeader'
import Span from '../components/CalculaSpan'
import Footer from '../components/Footer'
const Span1 = () => {
    return (
        <div>
            <Utilities />
          
            <Span />
            <Footer />
        </div>
    );
};

export default Span1;