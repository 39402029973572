import React from 'react';
import Fade from 'react-reveal/Fade';
const Mapa = () => {
    return (

            <div className="bg-white flex w-full  pt-8 lg:pt-0 lg:pl-14 p-10 mt-5 lg:mt-0 bg-gray-50">
                <div className="w-full flex flex-col lg:flex-row">
                    <div className="w-full flex flex-col lg:flex-row justify-center">
                    <div className="w-full flex justify-center">
                      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d29886.485567309213!2d-100.270625!3d20.554925!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa44e8202652fb15e!2sFiberlab!5e0!3m2!1ses!2smx!4v1633489264939!5m2!1ses!2smx" width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy">
                      </iframe>
                        </div>
                    </div>
                </div>
            </div>

    );
};

export default Mapa;
