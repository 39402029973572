import React from 'react';
import Utilities from '../utilities/MasterHeader'
import Footer from '../components/Footer'
import Formulario from '../components/formulario';
import Mapa from '../components/mapa';
const Laboratorio = () => {
    return (
        <div>
            <Utilities />
            <Formulario />
            <Mapa />
            <Footer />
        </div>
    );
};

export default Laboratorio;
