import React from 'react';
import Utilities from '../utilities/MasterHeader'
import WhatDo from '../components/WhatDo'
import Philosophy from '../components/Philosophy'
import Splittel from '../components/Splittel'
import Footer from '../components/Footer'
import Videos from '../components/videos'

const Media = () => {
    return (
        <div>
            <Utilities />
            <Videos />
            <Footer />
        </div>
    );
};

export default Media;
