import React from 'react';
import Fade from 'react-reveal/Fade';
import Cables from '../assets/images/OurLaboratory.PNG'
import { useTranslation } from "react-i18next";
const Laboratory = () => {
    const {t, i18n } = useTranslation();
    return (
        <Fade right>
            <div className="bg-white flex w-full lg:pt-0 pt-8 lg:pt-10 lg:pl-14 p-10 mt-5 lg:mt-0">
                <div className="w-full lg:h-96 lg:m-10 flex flex-col lg:flex-row lg:m-0 self-center">

                    <div className="w-full  flex flex-col justify-center self-center">
                        <div className="flex lg:text-4xl lg:font-bold text-gray-800 self-center lg:self-start lg:pb-0 text-3xl font-bold mb-0 font pl-10 xs:pl-0">{t('ot1')} </div>
                        <div className="flex lg:text-left text-gray-700 font self-center pl-10 pr-10 lg:text-base text-base text-justify mb-10 mt-6 xs:p-1">
                        {t('ob1')}
                        </div>
                    </div>
                    <div className=" lg:visible lg:p-0 mt-0 self-center lg:pt-0 lg:pr-10 lg:mb-28 ">
                      {/*  <img src={Cables} alt="" className="lg:ml-20 lg:w-auto lg:pt-2"></img> */}
                    </div>
                </div>
            </div>
        </Fade>
    );
};

export default Laboratory;
