import { useForm } from "react-hook-form";
import React, { useState, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import Constantes from "./Constantes";
import Swal from "sweetalert2";
import emailjs from "emailjs-com";

export default function App() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const form = useRef();

  const [contentEditor, setContentEditor] = useState();
  const handleEditorChange = (content, editor) => {
    setContentEditor(content);
  };
  const [contentDesc, setcontentDesc] = useState();
  const handleEditorChangeDesc = (content, editor) => {
    setcontentDesc(content);
  };
  const onSubmit = (data) => {
    const boton = document.getElementById('sendMessage');
    const cargaUtil = JSON.stringify({
      Nombre: data.Nombre,
      Correo: data.Correo,
      Servicio: data.Servicio,
      Descripcion: contentDesc,
      Prioridad: data.Prioridad,
      Equipo: data.Equipo,
      Serie: data.Serie,
      Marca: data.Marca,
      Modelo: data.Modelo,
      Accesorios: contentEditor,
    });
  
    // ¡Y enviarlo!
    fetch(`${Constantes.RUTA_API}/add_ordernservicio.php`, {
      method: "PUT",
      body: cargaUtil,
    })
      .then((res) => res.json())
      .catch((error) => console.error("Error:", error))
      .then(function (response) {
        console.log(response);
        boton.classList.add('hidden');
        const emailData = {
          Nombre: data.Nombre,
          Correo: data.Correo,
          Servicio: data.Servicio,
          Descripcion: contentDesc,
          Prioridad: data.Prioridad,
          Equipo: data.Equipo,
          Serie: data.Serie,
          Marca: data.Marca,
          Modelo: data.Modelo,
          Accesorios: contentEditor,
          id : response,
        };
        if (response) {
          emailjs
            .send(
              "service_2jwm465",
              "template_jhene47",
              emailData,
              "qU9BAxfSmOSGpQvYY"
            )
            .then(
              (result) => {
                window.location = "/Mensaje";
                console.log("win!");
              },
              (error) => {
                const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                    toast.addEventListener("mouseenter", Swal.stopTimer);
                    toast.addEventListener("mouseleave", Swal.resumeTimer);
                  },
                });
      
                Toast.fire({
                  icon: "error",
                  title: "Error en el envio de correo",
                });
              }
            );
        } else {
          boton.classList.remove('hidden');
          console.log("Error !!!");
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title: "Error en el registro",
          });
        }
      });

    /*   respuesta.json();

     console.log(respuesta); */
  };

  return (
    <form
      ref={form}
      className=" flex flex-col self-center lg:w-full lg:text-center font  lg:pl-96 lg:pr-96  bg-gray-500 bg-opacity-5"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex mb-8 m-8   space-x-4">
        <div className="w-1/2  h-12">
          {" "}
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="Nombre"
            >
              Nombre
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="Nombre"
              type="text"
              {...register("Nombre", { required: true, maxLength: 50 })}
              placeholder="Nombre"
            />

            {errors?.Nombre?.type === "required" && (
              <p className="text-red-600"> Nombre es obligatorio</p>
            )}
            {errors?.Nombre?.type === "maxLength" && (
              <p className="text-red-600">
                No puede exceder mas de 50 caracteres
              </p>
            )}
          </div>
        </div>
        <div className="w-1/2  h-12">
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="Correo"
            >
              Correo
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="Correo"
              {...register("Correo", { required: true, maxLength: 50 })}
              type="email"
              placeholder="Correo"
            />
            {errors?.Correo?.type === "required" && (
              <p className="text-red-600"> Correo es obligatorio</p>
            )}
            {errors?.Correo?.type === "maxLength" && (
              <p className="text-red-600">
                No puede exceder mas de 50 caracteres
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="flex mb-8 m-8  space-x-4">
        {/* LINEA 2 */}

        <div className="w-1/2  h-12">
          {" "}
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="Marca"
            >
              Marca
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="Marca"
              type="text"
              {...register("Marca", { required: true, maxLength: 150 })}
              placeholder="Marca"
            />
            {errors?.Marca?.type === "required" && (
              <p className="text-red-600"> Marca es obligatorio</p>
            )}
            {errors?.Marca?.type === "maxLength" && (
              <p className="text-red-600">
                No puede exceder mas de 150 caracteres
              </p>
            )}
          </div>
        </div>
        <div className="w-1/2  h-12">
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="Modelo"
            >
              Modelo
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="Modelo"
              {...register("Modelo", { required: true, maxLength: 200 })}
              type="text"
              placeholder="Modelo"
            />
            {errors?.Modelo?.type === "required" && (
              <p className="text-red-600"> Modelo es obligatorio</p>
            )}
            {errors?.Modelo?.type === "maxLength" && (
              <p className="text-red-600">
                No puede exceder mas de 200 caracteres
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="flex mb-8 m-8  space-x-4">
        {/* LINEA 3 */}

        <div className="w-1/2  h-12">
          {" "}
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="Serie"
            >
              Número de Serie
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="Serie"
              type="text"
              {...register("Serie", { required: true, maxLength: 25 })}
              placeholder="Serie"
            />
            {errors?.Serie?.type === "required" && (
              <p className="text-red-600"> Numero de Serie es obligatorio</p>
            )}
            {errors?.Serie?.type === "maxLength" && (
              <p className="text-red-600">
                No puede exceder mas de 25 caracteres
              </p>
            )}
          </div>
        </div>
        <div className="w-1/2  h-12">
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="Equipo"
            >
              Equipo
            </label>
            <select
              {...register("Equipo", { required: true })}
              className="block appearance-none w-full  border border-gray-200 text-gray-700 py-2 px-4 pr-6 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              name="Equipo"
            >
              <option></option>
              <option>Empalmadora</option>
              <option>OTDR</option>
              <option>Certificador</option>
              <option>Equipo de Inspeccion</option>
              <option>Medidor de Potencia</option>
              <option>Fuente de Luz</option>
              <option>Atenuador Optico</option>
              <option>Cortador de Presicion</option>
              <option>Analizador de Dispersion</option>
              <option>Analizador de Ethernet</option>
              <option>Otro</option>
            </select>
            {errors?.Equipo?.type === "required" && (
              <p className="text-red-600"> Equipo es obligatorio</p>
            )}
          </div>
        </div>
      </div>
      {/* LINEA 4 */}

      <div className="flex mb-8 m-8   space-x-4">
        <div className="w-1/2  h-12">
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="Servicio"
            >
              Tipo de Servicio
            </label>
            <select
              {...register("Servicio", { required: true })}
              className="block appearance-none w-full  border border-gray-200 text-gray-700 py-2 px-4 pr-6 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              name="Servicio"
            >
              <option></option>
              <option>Mantenimiento</option>
              <option>Reparacion</option>
              <option>Caligrafia</option>
              <option>Garantia</option>
              <option>Garantia FBL</option>
              <option>Garantia FMX</option>
              <option>Garantia OPT</option>
              <option>Activacion de GPS</option>
            </select>
            {errors?.Servicio?.type === "required" && (
              <p className="text-red-600"> Servicio es obligatorio</p>
            )}
          </div>
        </div>

        <div className="w-1/2  h-12">
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="Prioridad"
            >
              Prioridad
            </label>
            <select
              {...register("Prioridad", { required: true })}
              className="block appearance-none w-full  border border-gray-200 text-gray-700 py-2 px-4 pr-6 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              name="Prioridad"
            >
              <option></option>
              <option>normal</option>
              <option>urgente</option>
            </select>

            {errors?.Prioridad?.type === "required" && (
              <p className="text-red-600"> Prioridad es obligatorio</p>
            )}
          </div>
        </div>
      </div>
      <div className="flex ">
        <div className="w-full  h-12">
          {" "}
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="Descripcion"
          >
            Descripcion
          </label>
        </div>
      </div>
      <Editor
        {...register("Descripcion")}
        initialValue=" "
        name="Descripcion"
        value={contentDesc}
        onEditorChange={handleEditorChangeDesc}
        apiKey="sx0rs1ldfmfbx7tgul9rb4etb0k3uwt0yfhm6u8hh64bkjnm"
        init={{
          height: 500,
          menubar: false,
          plugins: [
            "advlist",
            "table",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "help",
            "wordcount",
          ],
          toolbar:
            "undo redo | casechange blocks | bold italic backcolor | " +
            "alignleft aligncenter alignright alignjustify | " +
            "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
          language: "es",
          elementpath: false,
        }}
      />
      <div className="h-full w-full ">
        <div className="w-full  h-12 ">
          {" "}
          <label
            className="block text-gray-700 text-sm font-bold m-4"
            htmlFor="Accesorios"
          >
            Accesorios
          </label>
        </div>
      </div>
      <Editor
        initialValue="<table style='border-collapse: collapse; border:1px solid black;' width='100%' border='1' data-mce-style='border-collapse: collapse; border: 1px solid black;'><tbody><tr><td style='text-align:center; font-weight:bold; font-size:18px; color:#fff; background:#2e3192;' colspan='3' data-mce-style='text-align: center; font-weight: bold; font-size: 18px; color: #fff; background: #2e3192;'>Lista de Accesorios</td></tr><tr><th style='font-weight:bold; font-size:16px; color:#000; background:#fff;' width='12%' data-mce-style='font-weight: bold; font-size: 16px; color: #000; background: #fff;'>Cantidad</th><th style='font-weight:bold; font-size:16px; color:#000; background:#fff;' width='20%' data-mce-style='font-weight: bold; font-size: 16px; color: #000; background: #fff;'>No. Serie</th><th style='font-weight:bold; font-size:16px; color:#000; background:#fff;' width='68%' data-mce-style='font-weight: bold; font-size: 16px; color: #000; background: #fff;'>Descripción</th></tr><tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr><tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr></tbody></table>"
        {...register("Accesorios")}
        name="Accesorios"
        apiKey="sx0rs1ldfmfbx7tgul9rb4etb0k3uwt0yfhm6u8hh64bkjnm"
        init={{
          height: 500,
          menubar: false,
          plugins: [
            "advlist",
            "table",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "help",
            "wordcount",
          ],
          toolbar:
            "undo redo | casechange blocks | bold italic backcolor | " +
            "alignleft aligncenter alignright alignjustify | " +
            "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
          elementpath: false,
        }}
        value={contentEditor}
        onEditorChange={handleEditorChange}
      />

      <div className="md:flex md:items-center m-5">
        <div className="md:w-1/3"></div>
        <div className="md:w-2/3">
          <button
            className="shadow bg-blue-500 hover:bg-blue-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded "
            type="submit"
            id="sendMessage"
            
          >
            Enviar
          </button>
        </div>
      </div>
    </form>
  );
}
