import React from "react";
import Cables from "../assets/images/wiki.png";
import Constantes from "./Constantes";

let url_str = window.location;
let url = new URL(url_str);
let search_params = url.searchParams;

let id = search_params.get("id");
class Ver extends React.Component {
  // Constructor

  constructor(props) {
    super(props);

    this.state = {
      items: [],
      DataisLoaded: false,
    };
  }

  componentDidMount() {
    fetch(`${Constantes.RUTA_API}/obtenerblogid.php?id=` + id)
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          items: json,
          DataisLoaded: true,
        });
      });
  }
  render() {
    const responsive = {
      0: { items: 1 },
      1024: { items: 1 },
    };

    const { DataisLoaded, items } = this.state;
    if (!DataisLoaded)
      return (
        <div>
          <h1> Pleses wait some time.... </h1>{" "}
        </div>
      );
    return (
      <>
        <div>
          {items.map((item) => (
            <div className=" flex flex-col  lg:w-full lg:font  lg:pl-96 lg:pr-96 lg:pb-20 rounded  ">
              <div className="flex lg:text-5xl lg:font-bold subtitulo self-center lg:pb-10 text-3xl font-bold  font pt-10">
                <img src={item.img} className="rounded-t-lg" />
              </div>
              <h5 className="text-gray-900 font-bold text-2xl tracking-tight ">
                {" "}
                {item.nombre}
              </h5>
              <div className="flex flex-col mt-10 sx:pl-28 sx:pr-28">
                <label className=" pb-2 text-gray-900">
                  <div 
                    dangerouslySetInnerHTML={{ __html: item.contenido }}
                  ></div>
                </label>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }
}

export default Ver;
