import React, { Component } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import Pagination from "react-js-pagination";
import Constantes from "./Constantes";
import { format } from "fecha";
/* import { format } from 'date-fns' */

class ver extends Component {
  state = {
    data: [],
    data1: [],

    activePage: 1,
    total: 1,
  };

  componentDidMount() {
    axios.get(`${Constantes.RUTA_API}/obtenerblogs.php?`).then((res) => {
      this.setState({
        data: res.data,
      });
    });
    axios.get(`${Constantes.RUTA_API}/obtenerallblogs.php?`).then((res) => {
      this.setState({
        data1: res.data,
      });
      this.setState({ total: res.data.length });
    });
  }

  handlePageChange = (pageNumber) => {
    axios
      .get(`${Constantes.RUTA_API}/obtenerblogpagina.php?page=${pageNumber}`)
      .then((res) => {
        this.setState({
          data: res.data,
        });
      });
    this.setState({ activePage: pageNumber });
  };
  render() {
    const allData = this.state.data.map((item) => {
      /*  console.log( item  );

      if (item === null) {
        return <p>Loading profile...</p>;
      } */
      const fecha = item.fecha.replace(/\s/, "T");
      return (
        <div className="bg-white  p-10 xs:col-span-3 sm:col-span-3">
          <a href={"/Descripcion?id=" + item.id}>
            <img src={item.img} className="rounded-t-lg" />
          </a>
          <div className="p-5 shadow-md border border-gray-200 ">
            <a>
              <h5 className="text-gray-900 font-bold text-2xl tracking-tight mb-2">
                {item.nombre}
              </h5>
            </a>
            {format(new Date(fecha), "mediumDate")}
            <p className="font-normal text-gray-700 mb-3">{item.descripcion}</p>
            <a
              className="text-white bg-gray-700 hover:bg-gray-400 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center"
              href={"/Descripcion?id=" + item.id}
            >
              Leer mas
            </a>
          </div>
        </div>
      );
    });
    /*  console.log( this.state.total  ); */

    return (
      <div className="App md:col-span-6 ">
        <div className=" grid gap-3 grid-cols-3  p-18 ">{allData}</div>

        <nav aria-label="Page navigation example">
          <Pagination
            itemClass=" inline-flex -space-x-px first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-black-500 bg-white text-black-500 "
            activeClass=" first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-black-500  bg-black-500 text-white"
            activeLinkClass="flex w-8 h-8 mx-0 rounded-full items-center justify-center leading-tight relative border border-solid border-gray-700  bg-gray-700 text-white"
            hideFirstLastPages={true}
            activePage={this.state.activePage}
            itemsCountPerPage={6}
            totalItemsCount={this.state.total}
            pageRangeDisplayed={3}
            onChange={this.handlePageChange}
          />
        </nav>
        <div className=" grid gap-3 grid-cols-3  p-18  "></div>
        <span>
          <br />{" "}
        </span>
      </div>
    );
  }
}
export default ver;
