import React from 'react';
import Fade from 'react-reveal/Fade';
import Cables from '../assets/images/fibra-optica.png'
import { useTranslation } from "react-i18next";
const How = () => {
    const {t, i18n } = useTranslation();
    return (

            <div className="bg-white flex w-full lg:HowEmpresaSize pt-8 lg:pt-0 lg:pl-14 p-10 mt-5 lg:mt-0 z-99" id="quienessomos">
                <div className="w-full flex flex-col lg:flex-row">
                    <div className="flex flex-col lg:mt-10">
                        <div className="flex lg:text-4xl lg:font-bold text-gray-800 self-center lg:self-start lg:pl-20 lg:pb-10 text-3xl font-bold mt-0 font mb-8 lg:mb-0">{t('ct1')} </div>
                        <div className=" lg:visible w-full self-center lg:pl-20 mb-5">

                            <img src={Cables} alt=""></img>

                        </div>
                    </div>

                    <div className="w-full lg:h-96 flex flex-col justify-center self-center pt-5 lg:pt-24 lg:pb-0 lg:mb-0 mb-10">
                        <div className="flex flex-col self-center lg:w-96 lg:text-center text-gray-700 font text-base">
                            {t('cb1a')} <span className="font-semibold text-xl subtitulo-blue">{t('cb1b')} </span>
                            {t('cb1c')}
                            <span className="font-semibold text-xl subtitulo-blue">The International Laboratory Accreditation Cooperation (ilac).</span>
                        </div>
                    </div>

                </div>
            </div>

    );
};

export default How;
