/*

------------------------------------------------------------------------------------------------
🆁🅼🅽 2022 07 01

------------------------------------------------------------------------------------------------
*/
import React, { useState, useRef } from "react";
import useInterval from "../hooks/useTimeout.js";
import emailjs from "emailjs-com";
import { useTranslation } from "react-i18next";
import Constantes from "./Constantes";

const Encuestas = () => {
  let url_str = window.location;
  let url = new URL(url_str);
  let search_params = url.searchParams; 
  
  let id = search_params.get('id');

  const { t, i18n } = useTranslation();
  const form = useRef();

  const [calidad, setCalidad] = useState("");

  const onOptionChange = (e) => {
    setCalidad(e.target.value);
  };
  const [tiempo, setTiempo] = useState("");

  const onOptionChange2 = (e) => {
    setTiempo(e.target.value);
  };
  const [atencion, setAtencion] = useState("");

  const onOptionChange3 = (e) => {
    setAtencion(e.target.value);
  };
  const [equipos, setEquipos] = useState("");

  const onOptionChange4 = (e) => {
    setEquipos(e.target.value);
  };

  const [mensaje, setMensaje] = useState("");
  const [politica, setPolitica] = useState(false);
  const [error, setError] = useState("");
  const [label, setLabel] = useState("Enviar");
  const [interval, setInterval] = useState(0);

  const [color, setColor] = useState(
    "bg-green-400 items-center hover:bg-green-400  text-center flex lg:justify-center font-bold pr-20"
  );
  const [bg, setBg] = useState(
    "h-screen bg-black flex flex-col justify-center p-60"
  );
  useInterval(() => {
    setColor(
      "p-2 bg-green-500 mt-5 text-white rounded w-40 self-center hover:bg-green-400 "
    );
    setLabel("Enviar");
    setBg("h-screen bg-black flex flex-col justify-center p-60");
  }, interval);
  const enviar = (e) => {
    e.preventDefault();
    console.log(politica);
    if (politica == false) {
      setColor(
        "p-2 bg-red-500 mt-5 text-white rounded w-full self-center hover:bg-red-400"
      );
      setLabel("Revisa el Encuesta, hay campos vacios");
      setInterval(5000);
    } else {
      emailjs
        .sendForm(
          "service_gao2e0k",
          "template_a9uk745",
          form.current,
          "qU9BAxfSmOSGpQvYY"
        )
        .then(
          (result) => {
            // Codificar nuestro videojuego como JSON
/* 
            const cargaUtil = JSON.stringify({
              id: 1,
              nombre: "rmsn",
              precio: "5.00",
              calificacion: 8,
            }); */

             const cargaUtil = JSON.stringify({
              id: id,
              pre1: calidad,
              pre2: tiempo,
              pre3: atencion,
              pre4: equipos,
              comen: mensaje,

            });

          /*   console.log(cargaUtil); */
            // ¡Y enviarlo!
            fetch(`${Constantes.RUTA_API}/actualizar_videojuego.php`, {
              method: "PUT",
              body: cargaUtil,
            })
              .then((res) => res.json())
              .catch((error) => console.error("Error:", error))
              .then(function(response) {

                if(response == true) {
                  window.location = "/"


                } else {
                  console.log('Error !!!');
                }
              }) 
             
            /*   respuesta.json();
        
              console.log(respuesta); */

          },
          (error) => {
            console.log(error.text);
          }
        );
    }
    //console.log(nombre.target.value)
  };
  return (
    <div className="flex bg-white w-full h-68  pt-8 lg:pt-0 lg:pl-14 p-10 mt-10 ">
      <div className="w-full flex flex-col lg:flex-row ">
        <div className="w-full lg:h-auto flex flex-col justify-center self-center ">
          <div className=" flex flex-col self-center lg:w-full lg:text-center font  lg:pl-96 lg:pr-96 lg:pb-20 rounded bg-gray-300">
            <div className="flex lg:text-4xl lg:font-bold subtitulo self-center lg:pb-10 text-3xl font-bold mb-10 font pt-10">
            {t('RMN18')}
            </div>
            <div className="flex flex-col mt-10 sx:pl-28 sx:pr-28">
              <label className="font text-left pb-2 text-gray-900">
                {error}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Encuestas;
