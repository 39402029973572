import React from 'react';
import Fade from 'react-reveal/Fade';
import Cables from '../assets/images/Calibration.PNG'
import { useTranslation } from "react-i18next";
const Calibration = () => {
    const {t, i18n } = useTranslation();
    return (
        <Fade right>
            <div className="bg-white flex w-full  pt-8 lg:pt-0 lg:pl-14 p-10 mt-0 lg:mt-0">
                <div className="lg:h-96 lg:m-10 w-full flex flex-col lg:flex-row ">
                    <div className=" lg:visible w-full lg:p-20 mb-0 pl-0 self-center flex justify-center">
                        <img src={Cables} className="lg:mt-20 lg:mr-20"></img>
                    </div>
                    <div className="w-full  flex flex-col justify-center">
                        <div className="flex lg:text-4xl lg:font-bold text-gray-800 lg:self-center md:self-start lg:pb-10 text-2xl font-bold mb-8 font">{t('ut1')} </div>
                        <div className="flex self-center  lg:text-center text-gray-700 font flex-col">
                            <div className="font text-start font-normal w-full text-justify lg:text-center">{t('ub1')} </div>
                            <div className="font text-start font-normal w-full text-justify lg:text-center">{t('ub2')}</div>
                            <div className="font text-start font-normal w-full text-justify lg:text-center">{t('ub3')}</div>
                            <div className="font text-start font-normal w-full text-justify lg:text-center">{t('ub4')}</div>
                            <div className="font text-start font-normal w-full text-justify lg:text-center">{t('ub5')}</div>
                            <div className="font text-start font-normal w-full text-justify lg:text-center">{t('ub6')}</div>
                            <div className="font text-start font-normal w-full text-justify lg:text-center">{t('ub7')}</div>
                            <div className="font text-start font-normal w-full text-justify lg:text-center">{t('ub8')}</div>
                            <div className="font text-start font-normal w-full text-justify lg:text-center">{t('ub9')}</div>
                            <div className="font text-start font-normal w-full text-justify lg:text-center">{t('ub10')}</div>
                            <div className="font text-start font-normal w-full text-justify lg:text-center">{t('ub11')}</div>
                        </div>
                    </div>
                </div>
            </div>
        </Fade>
    );
};

export default Calibration;
