import React from 'react';
import Fade from 'react-reveal/Fade';
import Cables from '../assets/images/wiki.png'
import Ambientales from '../assets/images/Laboratorioambiente.png'
import { useTranslation } from "react-i18next";
const WikiTemario = () => {
  const {t, i18n } = useTranslation();
    return (
        
            <div className="bg-white flex w-full lg:HowEmpresaSize pt-0 lg:pt-0 lg:pl-14 p-10 mt-0 lg:mt-0">
                <div className="w-full flex flex-col lg:flex-row">
                    <div className="flex flex-col lg:mt-0  bg-blue-900 lg:w-96 lg:pr-0 text-center border-2 rounded pb-10" >
                        <div className="flex lg:text-3xl lg:font-normal text-white self-start pl-5 lg:self-start lg:pl-20 lg:pb-10 text-2xl font-bold mt-0 font mb-8 lg:mb-0 pt-10" id="temario">{t('yb1')} </div>
                        <ul>
                            <li>
                              <a href="#laboratorio" className="font flex justify-start p-5 font-normal text-xl text-white">{t('yb2')}</a>
                              <ul className="pl-5">
                                <li>
                                <a href="#optica" className="font flex justify-start text-gray-300">{t('yb3')}</a>
                                </li>
                                <li>
                                <a href="#mecanicos" className="font flex justify-start text-gray-300">{t('yb4')}</a>
                                </li>
                                <li>
                                <a href="#ambientales" className="font flex justify-start text-gray-300">{t('yb5')}</a>
                                </li>
                                <li>
                                <a href="#materiales" className="font flex justify-start text-gray-300">{t('yb6')}</a>
                                </li>
                                <li>
                                <a href="#calibracion" className="font flex justify-start text-gray-300">{t('yb7')}</a>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a href="#servicios" className="font flex justify-start p-5 font-normal text-xl text-white">{t('yb8')}</a>
                              <ul className="pl-5">
                                <li>
                                <a href="#electronica" className="font flex justify-start text-gray-300">{t('yb9')}</a>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a href="#acreditaciones" className="font flex justify-start p-5 font-normal text-xl text-white">{t('yb10')}</a>
                              <ul className="pl-5">
                                <li>
                                <a href="#acreditado" className="font flex justify-start text-gray-300">{t('yb11')}</a>
                                </li>
                               </ul>
                            </li>
                        </ul>
                    </div>

                    <div className="w-full lg:h-auto flex flex-col justify-center self-center pt-5 lg:pt-10 lg:pb-0 lg:mb-0 mb-10 bg-gray-200 pt-10 pb-10 ">
                        <div className="flex flex-col lg:self-start self-center lg:pl-20 lg:w-full lg:text-center text-gray-700 font text-base pl-5">
                            <div className="flex lg:text-2xl lg:font-bold text-gray-800 self-start lg:self-start lg:pl-0 lg:pb-10 text-2xl font-bold mt-0 font mb-8 lg:mb-0" id="laboratorio">{t('zt1')} </div>
                            <div id="optica" className="self-start font text-xl">{t('zb1')} </div>
                            <div className="self-start font font-semibold mb-10">{t('zb3')}</div>
                            <span className="self-start font text-justify pr-10 ">
                            <strong>{t('zb4')}</strong><br/><br/>

                              <span>{t('zb5')}</span><br/>

                              <span>{t('zb6')}</span><br/>

                              <span>{t('zb7')}</span><br/>
                              <span>{t('zb8')}</span><br/>
                              <span>{t('zb9')}</span><br/><br/>

                                <strong>{t('zb10')}</strong><br/><br/>

                              <span>{t('zb11')}</span><br/>
                              <span>{t('zb12')}</span><br/>
                              <span>{t('zb13')}</span><br/>
                              <span>{t('zb14')}</span><br/><br/>


                              <strong>{t('zb15')}</strong><br/><br/>

                                <span>{t('zb16')}</span><br/>
                                <span>{t('zb17')}</span><br/>
                                <span>{t('zb18')}</span><br/>
                                <span>{t('zb19')}</span><br/>
                                <span>{t('zb20')}</span><br/>
                                <span>{t('zb21')}</span><br/>
                                <a href="#temario" className="flex w-20 text-center self-center justify-center bg-blue-200 p-2 mt-10 ">{t('zb22')}</a>
                            </span>
                        </div>
                        <div className="flex flex-col lg:self-start self-center lg:pl-20 lg:w-full lg:text-center text-gray-700 font text-base mt-10 bg-gray-100 pt-10 pb-10 pl-5">

                            <div id="mecanicos" className="self-start font text-xl" >{t('zt2')}</div>
                            <div className="self-start font font-semibold mb-10">{t('zb23')}</div>
                            <span className="self-start font text-justify pr-10">
                            <strong>{t('zb24')}</strong><br/><br/>

                            <strong>{t('zb25')}</strong><br/><br/>
                            		<span className="pl-0">{t('zb26')}</span><br/>
                            		<span className="pl-0">{t('zb27')}</span><br/>
                            		<span className="pl-0">{t('zb28')}</span><br/>
                            		<span className="pl-0">{t('zb29')}</span><br/>
                            		<span className="pl-0">{t('zb30')}</span><br/>
                            		<span className="pl-0">{t('zb31')}</span><br/>
                            		<span className="pl-0">{t('zb32')}</span><br/>
                            		<span className="pl-0">{t('zb33')}</span><br/><br/>

                            <strong>{t('zb34')}</strong><br/><br/>

                                <strong className="pl-0">{t('zb35')}</strong><br/><br/>
                            		<span className="pl-0">{t('zb36')}</span><br/><br/>
                                <strong className="pl-0">{t('zb37')}</strong><br/><br/>
                            		<span className="pl-0">{t('zb38')}</span><br/>
                            		<span className="pl-0">{t('zb39')}</span><br/>
                            		<span className="pl-0">{t('zb40')}</span><br/>
                            		<span className="pl-0">{t('zb41')}</span><br/><br/>

                                <strong className="pl-0">{t('zb42')}</strong><br/><br/>


                            <strong>{t('zb43')}</strong><br/><br/>

                            <strong className="pl-0">{t('zb44')}</strong><br/><br/>
                            		<span className="pl-0">{t('zb45')}</span><br/>
                            		<span className="pl-0">{t('zb46')}</span><br/>
                            		<span className="pl-0">{t('zb47')}</span><br/>
                            		<span className="pl-0">{t('zb48')}</span><br/>
                            		<span className="pl-0">{t('zb49')}</span><br/><br/>
                            <strong className="pl-0">{t('zb50')}</strong><br/><br/>

                            <strong>{t('zb51')}</strong><br/><br/>

                            <a href="#temario" className="flex w-20 text-center self-center justify-center bg-blue-200 p-2 mt-10">{t('zb52')}</a>
                            </span>
                        </div>

                      <div className="flex flex-col lg:self-start self-center lg:pl-20 lg:w-full lg:text-center text-gray-700 font text-base mt-10 bg-gray-200 pt-10 pb-10 pl-5">

                            <div id="ambientales" className="self-start font text-xl" >{t('zt3')} </div>
                            <div className="self-start font font-semibold mb-10">{t('zb53')}</div>
                            <span className="self-start font text-justify pr-10">
                            <strong>{t('zb54')}</strong><br/><br/>
                            <span>{t('zb55')}</span><br/><br/>
                            <span>{t('zb56')}</span><br/><br/>
                            <div className=" lg:visible lg:p-0 mt-0 self-center lg:pt-0 lg:pl-10 lg:mb-10 lg:mt-5 mt-10 mb-10 ">
                                <img src={Ambientales} alt="" className="lg:ml-0 lg:w-auto"></img>
                            </div>
                            <strong>{t('zb57')}</strong><br/><br/>
                            <span>{t('zb58')}</span><br/><br/>

                            <strong>{t('zb59')}</strong><br/><br/>

                            <strong>{t('zb60')}</strong><br/><br/>

                            <strong>{t('zb61')}</strong><br/><br/>
                            		<strong className="pl-0">{t('zb62')}</strong><br/>
                            		<strong className="pl-0">{t('zb63')}</strong><br/>
                            		<span className="pl-0">{t('zb64')}</span><br/>
                            		<span className="pl-0">{t('zb65')}</span><br/><br/>

                            <strong>{t('zb66')}</strong><br/><br/>

                            <strong className="pl-0">{t('zb67')}</strong><br/>
                            <strong className="pl-0">{t('zb68')}</strong><br/>
                            <strong className="pl-0">{t('zb69')}</strong><br/><br/>

                            <strong>{t('zb70')}</strong><br/><br/>

                            <strong className="pl-0">{t('zb71')}</strong><br/>
                            <strong className="pl-0">{t('zb72')}</strong><br/>
                            <strong className="pl-0">{t('zb73')}</strong><br/><br/>

                            <span>{t('zb74')}</span><br/><br/>
                            <a href="#temario" className="flex w-20 text-center self-center justify-center bg-blue-200 p-2 mt-10">{t('zb75')}</a>
                            </span>
                        </div>

                        <div className="flex flex-col lg:self-start self-center lg:pl-20 lg:w-full lg:text-center text-gray-700 font text-base mt-10 bg-gray-100 pt-10 pb-10 pl-5">

                            <div id="materiales" className="self-start font text-xl">{t('zt4')} </div>
                            <div className="self-start font font-semibold mb-10">{t('zb76')}</div>
                            <span className="self-start font text-justify pr-10">
                            <strong>{t('zb77')}</strong><br/><br/>

                            <strong>{t('zb78')}</strong><br/><br/>

                            <strong>{t('zb79')}</strong><br/><br/>

                            <strong>{t('zb80')}</strong><br/><br/>

                            <strong>{t('zb81')}</strong><br/><br/>

                            <strong>{t('zb82')}</strong><br/><br/>

                            <span className="pl-0">{t('zb83')}</span><br/>
                            <span className="pl-0">{t('zb84')}</span><br/>
                            <span className="pl-0">{t('zb85')}</span><br/>
                            <span className="pl-0">{t('zb86')}</span><br/><br/>


                            <a href="#temario" className="flex w-20 text-center self-center justify-center bg-blue-200 p-2 mt-10">{t('zb87')}</a>
                            </span>
                        </div>

                        <div className="flex flex-col lg:self-start self-center lg:pl-20 lg:w-full lg:text-center text-gray-700 font text-base mt-10 bg-gray-200 pt-10 pb-10 pl-5">

                            <div id="calibracion" className="self-start font text-xl">{t('zt5')}</div>
                            <div className="self-start font font-semibold mb-10">{t('zb88')}</div>
                            <span className="self-start font text-justify pr-10">
                            <span className="pl-0">{t('zb89')}</span><br/>
                            <span className="pl-0">{t('zb90')}</span><br/>
                            <span className="pl-0">{t('zb91')}</span><br/>
                            <span className="pl-0">{t('zb92')}</span><br/>
                            <span className="pl-0">{t('zb93')}</span><br/>
                            <span className="pl-0">{t('zb94')}</span><br/>
                            <span className="pl-0">{t('zb95')}</span><br/>
                            <span className="pl-0">{t('zb96')}</span><br/>
                            <span className="pl-0">{t('zb97')}</span><br/>
                            <span className="pl-0">{t('zb98')}</span><br/>
                            <span className="pl-0">{t('zb99')}</span><br/><br/>
                            <a href="#temario" className="flex w-20 text-center self-center justify-center bg-blue-200 p-2 mt-10">{t('zb100')}</a>
                            </span>
                        </div>

                        <div className="flex flex-col lg:self-start self-center lg:pl-20 lg:w-full lg:text-center text-gray-700 font text-base mt-10 bg-gray-100 pt-10 pb-10 pl-5">
                            <div className="flex lg:text-2xl lg:font-bold text-gray-800 self-start lg:self-start lg:pl-0 lg:pb-10 text-2xl font-bold mt-0 font mb-8 lg:mb-0" id="servicios">{t('zt6')}</div>
                            <div id="electronica" className="self-start font text-xl pb-10 ">{t('zb101')}</div>

                            <span className="self-start font text-justify pr-10">
                            <span className="pl-0">{t('zb102')}</span><br/>
                            <span className="pl-0">{t('zb103')}</span><br/>
                            <span className="pl-0">{t('zb104')}</span><br/>
                            <span className="pl-0">{t('zb105')}</span><br/><br/>

                            <a href="#temario" className="flex w-20 text-center self-center justify-center bg-blue-200 p-2 mt-10">{t('zb106')}</a>
                            </span>
                        </div>

                        <div className="flex flex-col lg:self-start self-center lg:pl-20 lg:w-full lg:text-center text-gray-700 font text-base mt-10 bg-gray-200 pt-10 pb-10 pl-5 ">
                            <div className="flex lg:text-2xl lg:font-bold text-gray-800 self-start lg:self-start lg:pl-0 lg:pb-10 text-2xl font-bold mt-0 font mb-8 lg:mb-0" id="acreditaciones">{t('zt7')}</div>
                            <div id="acreditado" className="self-start font text-xl pb-10 ">{t('zb107')}</div>
                            <strong className="self-start font font-semibold mb-10">{t('zb108')}</strong><br/>
                            <span className="self-start font text-justify pr-10">
                            <span>1.-{t('zb109')}<strong>{t('zb110')}</strong></span><br/><br/>

                            <span>2.-{t('zb111')}<strong>{t('zb112')}</strong></span><br/><br/>

                            <span>3.-<strong>{t('zb113')}</strong> {t('zb114')}</span><br/><br/>

                            <span>4.-{t('zb115')}<strong>{t('zb116')}</strong></span><br/><br/>
                            <span>5.-{t('zb117')}<strong>{t('zb118')}</strong></span><br/><br/>

                            <a href="#temario" className="flex w-20 text-center self-center justify-center bg-blue-200 p-2 mt-10">{t('zb119')}</a>

                            </span>
                        </div>

                    </div>

                </div>
            </div>
        
    );
};

export default WikiTemario;
