import React from 'react';
import Fade from 'react-reveal/Fade';
import { useTranslation } from "react-i18next";
const WhatDo = () => {
    const {t, i18n } = useTranslation();
    return (
        <Fade left>
            <div className="WhatDo-background WhatDoSize flex w-full pt-8 lg:pt-0 lg:pl-14 p-10 mt-5 lg:mt-0">
                <div className="w-full flex flex-col lg:flex-row">
                    <div className="w-full flex lg:flex-row flex-col justify-center pt-10">
                        <div className="w-full lg:p-20">
                            <div className="flex lg:text-4xl lg:font-bold subtitulo-blue self-center lg:pb-0 text-3xl font-bold mb-10 font lg:justify-start justify-center lg:pl-10" id="quehacemos">{t('et1')} </div>
                            <div className="flex self-center lg:w-96 lg:text-center text-white font">
                            {t('eb1a')}


                            </div>
                            <div className="flex self-center lg:w-96 lg:text-center text-white pt-10 font">
                            {t('eb1b')}
                            </div>
                        </div>
                        <div className="know-background rounded lg:mr-5 w-full flex justify-center lg:ml-10 flex lg:flex-row flex-col mt-5 p-5">
                        <div className="flex self-center lg:w-96 lg:text-center text-white pt-2 font flex-col">
                            <span className="text-left mb-3 font">{t('eb1c')} </span>
                            <span className="text-left mb-3 font">{t('eb1d')}</span>
                            <span className="text-left mb-3 font">{t('eb1e')}</span>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fade>
    );
};

export default WhatDo;
