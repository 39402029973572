import React from "react";

class Span1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: "Span",
      input1: "",
      input2: "",
      input3: "",
      input4: "",
      input5: "",
      input6: "",
      input7: "",
      hidden: "flex mb-12;visibility: hidden",

      active:
        "inline-flex items-center py-2 px-4 text-sm font-medium text-white-900 bg-transparent rounded-l-lg border border-gray-500 bg-blue-500 text-white focus:z-10 focus:ring-2 focus:ring-blue-700 focus:bg-blue-500 focus:text-white dark:border-white text-white text-white dark:bg-blue-800 dark:focus:bg-blue-800",
      active2:
        "inline-flex items-center py-2 px-4 text-sm font-medium text-gray-900 bg-transparent border-t border-b border-gray-500 hover:bg-blue-200 hover:text-white focus:z-10 focus:ring-2 focus:ring-blue-500 focus:bg-blue-500 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-blue-700 dark:focus:bg-blue-700",
      active3:
        "inline-flex items-center py-2 px-4 text-sm font-medium text-gray-900 bg-transparent rounded-r-md border border-gray-500 hover:bg-blue-200 hover:text-white focus:z-10 focus:ring-2 focus:ring-blue-500 focus:bg-blue-500 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-blue-700 dark:focus:bg-blue-700",
    };
  }

  render() {
    /*    const handleChange = (e) => {
      this.setState({ selectedValue: e.target.value });
      this.setState({ input1: "" });
      this.setState({ input2: "" });
      this.setState({ input3: "" });
      this.setState({ input4: "" });
      this.setState({ input5: "" });
      this.setState({ input6: "" });
      this.setState({ input7: "" });


    }; */
    const handleChange1 = (e) => {
      this.setState({ input1: e.target.value });
      this.setState({ input7: "" });
      this.setState({ hidden: "hidden" });
    };
    const handleChange2 = (e) => {
      this.setState({ input2: e.target.value });
      this.setState({ input7: "" });
      this.setState({ hidden: "hidden" });
    };
    const handleChange3 = (e) => {
      this.setState({ input3: e.target.value });
      this.setState({ input7: "" });
      this.setState({ hidden: "hidden" });
    };
    const handleChange4 = (e) => {
      this.setState({ input4: e.target.value });
      this.setState({ input7: "" });
      this.setState({ hidden: "hidden" });
    };
    const handleChange5 = (e) => {
      this.setState({ input5: e.target.value });
      this.setState({ input7: "" });
      this.setState({ hidden: "hidden" });
    };
    const handleChange6 = (e) => {
      this.setState({ input6: e.target.value });
      this.setState({ input7: "" });
      this.setState({ hidden: "hidden" });
    };

    let A;
    let VelocidadViento;
    let P;
    let CD;
    let TVPC;
    let PV;
    let PC;
    let PT;
    let ResultadoSPAN;
    let ResultadoFLECHA;
    let ResultadoTENSION;

    A = this.state.input4 / 1000;
    VelocidadViento = this.state.input6 / 3.6;
    P = 0.613 * VelocidadViento * VelocidadViento;
    CD = 1.2;
    TVPC = A * P * CD;
    const totalTVPC = TVPC.toFixed(2);
    PV = totalTVPC;
    PC = this.state.input5 * 9.8066;
    const totalPC = PC.toFixed(2);

    PT = Math.sqrt(parseFloat(PV ** 2) + parseFloat(totalPC ** 2));
    /*  const totalPT = PT.toFixed(2); */

    ResultadoSPAN = Math.sqrt(
      (this.state.input1 - (PT * this.state.input3) / 2) *
        ((8 * this.state.input2) / PT)
    );
    const totalResultadoSPAN = ResultadoSPAN.toFixed(2);
    ResultadoFLECHA =
      1 /
      ((this.state.input1 - (PT * this.state.input3) / 2) *
        (8 / (this.state.input2 * this.state.input2 * PT)));
    const totalResultadoResultadoFLECHA = ResultadoFLECHA.toFixed(2);

    ResultadoTENSION =
      (PT * this.state.input2 ** 2) / (8 * this.state.input1) +
      (PT * this.state.input3) / 2;
    const totalResultadoResultadoTENSION = ResultadoTENSION.toFixed(2);

    let RESULTADOFINAL;
    let RESULTADOFINAL1;

    const shoot = () => {
      console.log("Span = " + ResultadoSPAN);
      console.log("Flecha = " + totalResultadoResultadoFLECHA);
      console.log("TENSION = " + totalResultadoResultadoTENSION);
      console.log("*******************");

      if (this.state.selectedValue == "Span") {
        RESULTADOFINAL = totalResultadoSPAN;
      } else if (this.state.selectedValue == "Flecha") {
        RESULTADOFINAL = totalResultadoResultadoFLECHA;
      } else if (this.state.selectedValue == "Tensión de operación") {
        RESULTADOFINAL = totalResultadoResultadoTENSION;
      }
      if (RESULTADOFINAL == "NaN") {
        RESULTADOFINAL1 = "NaN";
      } else {
        RESULTADOFINAL1 = RESULTADOFINAL;
      }
      this.setState({ input7: RESULTADOFINAL1 });
      this.setState({ hidden: "flex mb-12;visibility: visible;" });

      /*   console.log("Span = " + RESULTADOFINAL); */
    };

    const shoot1 = () => {
      this.setState({ selectedValue: "Span" });
      this.setState({ input1: "" });
      this.setState({ input2: "" });
      this.setState({ input3: "" });
      this.setState({ input4: "" });
      this.setState({ input5: "" });
      this.setState({ input6: "" });
      this.setState({ input7: "" });
      this.setState({ hidden: "hidden" });

      this.setState({
        active:
          "inline-flex items-center py-2 px-4 text-sm font-medium text-white-900 bg-transparent rounded-l-lg border border-gray-500 bg-blue-500 text-white focus:z-10 focus:ring-2 focus:ring-blue-700 focus:bg-blue-500 focus:text-white dark:border-white text-white text-white dark:bg-blue-800 dark:focus:bg-blue-800",
      });
      this.setState({
        active2:
          "inline-flex items-center py-2 px-4 text-sm font-medium text-gray-900 bg-transparent border-t border-b border-gray-500 hover:bg-blue-200 hover:text-white focus:z-10 focus:ring-2 focus:ring-blue-500 focus:bg-blue-500 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-blue-700 dark:focus:bg-blue-700",
      });
      this.setState({
        active3:
          "inline-flex items-center py-2 px-4 text-sm font-medium text-gray-900 bg-transparent rounded-r-md border border-blue-900 hover:bg-blue-200 hover:text-white focus:z-10 focus:ring-2 focus:ring-blue-500 focus:bg-blue-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-blue-700 dark:focus:bg-blue-700",
      });
    };
    const shoot2 = () => {
      this.setState({ selectedValue: "Flecha" });
      this.setState({ input1: "" });
      this.setState({ input2: "" });
      this.setState({ input3: "" });
      this.setState({ input4: "" });
      this.setState({ input5: "" });
      this.setState({ input6: "" });
      this.setState({ input7: "" });
      this.setState({ hidden: "hidden" });

      this.setState({
        active:
          "inline-flex items-center py-2 px-4 text-sm font-medium text-gray-900 bg-transparent rounded-l-lg border border-gray-500 hover:bg-blue-200 hover:text-white focus:z-10 focus:ring-2 focus:ring-blue-500 focus:bg-blue-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-blue-700 dark:focus:bg-blue-700",
      });
      this.setState({
        active2:
          "inline-flex items-center py-2 px-4 text-sm font-medium text-white-900 bg-transparent border-t border-b border border-gray-500 bg-blue-500 text-white focus:z-10 focus:ring-2 focus:ring-blue-700 focus:bg-blue-500 focus:text-white dark:border-white text-white text-white dark:bg-blue-800 dark:focus:bg-blue-800",
      });
      this.setState({
        active3:
          "inline-flex items-center py-2 px-4 text-sm font-medium text-gray-900 bg-transparent rounded-r-md border border-gray-500 hover:bg-blue-200 hover:text-white focus:z-10 focus:ring-2 focus:ring-blue-500 focus:bg-blue-500 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-blue-700 dark:focus:bg-blue-700",
      });
    };

    const shoot3 = () => {
      this.setState({ selectedValue: "Tensión de operación" });
      this.setState({ input1: "" });
      this.setState({ input2: "" });
      this.setState({ input3: "" });
      this.setState({ input4: "" });
      this.setState({ input5: "" });
      this.setState({ input6: "" });
      this.setState({ input7: "" });
      this.setState({ hidden: "hidden" });

      this.setState({
        active:
          "inline-flex items-center py-2 px-4 text-sm font-medium text-gray-900 bg-transparent rounded-l-lg border border-gray-500 hover:bg-blue-200 hover:text-white focus:z-10 focus:ring-2 focus:ring-blue-500 focus:bg-blue-500 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-blue-700 dark:focus:bg-blue-700",
      });
      this.setState({
        active2:
          "inline-flex items-center py-2 px-4 text-sm font-medium text-gray-900 bg-transparent border-t border-b border-gray-500 hover:bg-blue-200 hover:text-white focus:z-10 focus:ring-2 focus:ring-blue-500 focus:bg-blue-500 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-blue-700 dark:focus:bg-blue-700",
      });
      this.setState({
        active3:
          "inline-flex items-center py-2 px-4 text-sm font-medium text-white-900 bg-transparent rounded-r-md border border border-gray-500 bg-blue-500 text-white focus:z-10 focus:ring-2 focus:ring-blue-700 focus:bg-blue-500 focus:text-white dark:border-white text-white text-white dark:bg-blue-800 dark:focus:bg-blue-800",
      });
    };
    return (
      <div className="bg-white ">
        <div className="mx-auto w-full px-4 sm:px-6 lg:px-7">
          <div className="flex ... ">
            <div className="w-2/6 xs:hidden  sm:hidden ">
              <br></br>
              <br></br>

              <div className="sticky top-10 float-center  ml-20 mt-10 ">
                <ul className="max-w-md divide-y divide-gray-200 dark:divide-gray-700 ">
                  <a href="#">
                    <li className="pb-3 sm:pb-4 ">
                      <div className="flex items-center space-x-4 ">
                        <div className="flex-1 min-w-0">
                          <p className="text-sm text-gray-700 truncate dark:text-gray-700 hover:text-blue-700 text-left">
                            <b>INICIO</b>
                          </p>
                        </div>
                      </div>
                    </li>
                  </a>
                  <hr  className="w-4/5"  />
                  <a href="#1">
                    <li className="pb-3 sm:pb-4">
                      <div className="flex items-center space-x-4">
                        <div className="flex-1 min-w-0">
                          <p className="text-sm text-gray-700 truncate dark:text-gray-700 hover:text-blue-700 text-left">
                            <b>¿Qué es el SPAN en un tendido de cable?</b>
                          </p>
                        </div>
                      </div>
                    </li>
                  </a>
                  <hr  className="w-4/5" ></hr>
                  <a href="#2">
                    <li className="pb-3 sm:pb-4">
                      <div className="flex items-center space-x-4">
                        <div className="flex-1 min-w-0">
                          <p className="text-sm text-gray-700 truncate dark:text-gray-700 hover:text-blue-700 text-left">
                            <b>¿Por qué es importante el SPAN?</b>
                          </p>
                        </div>
                      </div>
                    </li>
                  </a>
                  <hr  className="w-4/5" ></hr>
                  <a href="#3">
                    <li className="pb-3 sm:pb-4">
                      <div className="flex items-center space-x-4">
                        <div className="flex-1 min-w-0">
                          <p className="text-sm text-gray-700 truncate dark:text-gray-700 hover:text-blue-700 text-left">
                            <b>¿De qué parámetros depende el SPAN?</b>
                          </p>
                        </div>
                      </div>
                    </li>
                  </a>
                  <hr  className="w-4/5" ></hr>
                  <a href="#4">
                    <li className="pb-3 sm:pb-4">
                      <div className="flex items-center space-x-4">
                        <div className="flex-1 min-w-0">
                          <p className="text-sm text-gray-700 truncate dark:text-gray-700 hover:text-blue-700 text-left">
                            <b>¿Qué es la tensión de operación?</b>
                          </p>
                        </div>
                      </div>
                    </li>
                  </a>
                  <hr  className="w-4/5" ></hr>
                  <a href="#5">
                    <li className="pb-3 sm:pb-4">
                      <div className="flex items-center space-x-4">
                        <div className="flex-1 min-w-0">
                          <p className="text-sm text-gray-700 truncate dark:text-gray-700 hover:text-blue-700 text-left">
                            <b>¿Qué es la flecha?</b>
                          </p>
                        </div>
                      </div>
                    </li>
                  </a>
                  <hr  className="w-4/5" ></hr>
                  <a href="#6">
                    <li className="pb-3 sm:pb-4">
                      <div className="flex items-center space-x-4">
                        <div className="flex-1 min-w-0">
                          <p className="text-sm text-gray-700 truncate dark:text-gray-700 hover:text-blue-700 text-left">
                            <b>¿Qué es el desnivel entre postes?</b>
                          </p>
                        </div>
                      </div>
                    </li>
                  </a>
                  <hr  className="w-4/5" ></hr>
                  <a href="#7">
                    <li className="pb-3 sm:pb-4">
                      <div className="flex items-center space-x-4">
                        <div className="flex-1 min-w-0">
                          <p className="text-sm text-gray-700 truncate dark:text-gray-700 hover:text-blue-700 text-left">
                            <b>¿Qué es la densidad lineal del cable?</b>
                          </p>
                        </div>
                      </div>
                    </li>
                  </a>
                </ul>
              </div>
            </div>
            <br></br>
            <br></br>
            <div className=" xs:w-full  sm:w-full w-3/6 ">
              <br></br>
              <br></br>

              <div className="w-full text-left text-lg ">
                <h1>
                  <b>CALCULADORA DE SPAN</b>
                </h1>
              </div>
              <div className="w-full text-left text-sm">
                Calcula los parámetros de tendido de un cable de fibra óptica,
                SPAN, FLECHA y TENSIÓN DE OPERACIÓN, en función de las
                características del cable (peso y diámetro) y de las condiciones
                ambientales de viento. La calculadora puede ser empleada como apoyo para elegir un cable, sin embargo, los valores particulares del tendido de campo deben ser analizados con el estudio de ingeniería de tendido.
              </div>
              <br></br>

              {/* <div className="flex flex-wrap ">
                <div className="w-1/2 mb-5  text-center block py-2.5 text-sm rounded border border-dark-200 dark:border-dark-700">
                  SELECCIONA EL PARÁMETRO A CALCULAR
                </div>
                <div className="w-1/2 mb-5 ">
                  <select
                    onChange={(e) => handleChange(e)}
                    className="text-center block py-2.5 px-0 w-full text-sm text-gray-800 bg-gray-50 border-0 border-b-2 border-blue-200 appearance-none dark:text-blue-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                  >
                    <option value="Span">Span</option>
                    <option value="Flecha">Flecha</option>
                    <option value="Tensión de operación">
                      Tensión de operación
                    </option>
                  </select>
                </div>
              </div> */}
              <div className="rounded border border-dark-900 dark:border-dark-900 ">
                <br></br>
                <div className="flex mb-12">
                  <div className="w-full   ">
                    <div
                      className="inline-flex rounded-md shadow-sm"
                      role="group"
                    >
                      <button
                        onClick={shoot1}
                        className={this.state.active}
                        type="button"
                      >
                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp; Span
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </button>
                      <button
                        type="button"
                        onClick={shoot2}
                        className={this.state.active2}
                      >
                        &nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Flecha
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </button>
                      <button
                        type="button"
                        onClick={shoot3}
                        className={this.state.active3}
                      >
                        Tensión de operación
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap  ">
                  <div className="w-full  mb-4 text-left">
                    &nbsp;&nbsp; 1. Introduce los parámetros del tendido:
                  </div>
                </div>
                <div className="flex  ">
                  <div className="w-1/3  ">
                    <label
                      htmlFor="email"
                      className="block mb-2 text-sm font-medium "
                    >
                      {this.state.selectedValue == "Tensión de operación"
                        ? "Flecha"
                        : "Tensión de operación"}
                      &nbsp;&nbsp;
                      {this.state.selectedValue == "Tensión de operación"
                        ? "[m]"
                        : "[N]"}
                    </label>
                  </div>
                  <div className="w-1/3  ">
                    <label
                      htmlFor="email"
                      className="block mb-2 text-sm font-medium "
                    >
                      {this.state.selectedValue == "Span" ? "Flecha" : "Span"}
                      &nbsp;&nbsp; [m]
                    </label>
                  </div>
                  <div className="w-1/3  ">
                    <label
                      htmlFor="email"
                      className="block mb-2 text-sm font-medium "
                    >
                      Desnivel entre postes &nbsp;&nbsp; [m]
                    </label>
                  </div>
                </div>
                <div className="flex ">
                  <div className="w-1/3  ">
                    <input
                      type="number"
                      name="floating_email"
                      id="floating_email"
                      onChange={(e) => handleChange1(e)}
                      value={this.state.input1}
                      className="w-full max-w-[200px] rounded-lg border border-slate-200 px-2 py-1 hover:border-blue-500 focus:outline-none focus:ring focus:ring-blue-500/40 active:ring active:ring-blue-500/40"
                      placeholder=" "
                      required
                    />
                  </div>
                  &nbsp;&nbsp;
                  <div className="w-1/3  ">
                    <input
                      onChange={(e) => handleChange2(e)}
                      value={this.state.input2}
                      type="number"
                      name="floating_email"
                      id="floating_email"
                      className="w-full max-w-[200px] rounded-lg border border-slate-200 px-2 py-1 hover:border-blue-500 focus:outline-none focus:ring focus:ring-blue-500/40 active:ring active:ring-blue-500/40"
                      placeholder=" "
                      required
                    />
                  </div>
                  &nbsp;&nbsp;
                  <div className="w-1/3  ">
                    <input
                      onChange={(e) => handleChange3(e)}
                      value={this.state.input3}
                      type="number"
                      name="floating_email"
                      id="floating_email"
                      className="w-full max-w-[200px] rounded-lg border border-slate-200 px-2 py-1 hover:border-blue-500 focus:outline-none focus:ring focus:ring-blue-500/40 active:ring active:ring-blue-500/40"
                      placeholder=" "
                      required
                    />
                  </div>
                </div>
                <br></br>
                <div className="flex  ">
                  <div className="w-full  text-left">
                    &nbsp;&nbsp; 2. Introduce las especificaciones del cable:
                  </div>
                </div>
                <br></br>

                <div className="flex mb-1">
                  <div className="w-1/2  ">
                    <label
                      htmlFor="email"
                      className="block mb-2 text-sm font-medium "
                    >
                      Diámetro del cable [mm]
                    </label>
                  </div>
                  <div className="w-1/2  ">
                    <label
                      htmlFor="email"
                      className="block mb-1 text-sm font-medium "
                    >
                      Densidad lineal del cable [kg/m]
                    </label>
                  </div>
                </div>
                <div className="flex mb-1">
                  <div className="w-1/2  ">
                    <input
                      onChange={(e) => handleChange4(e)}
                      value={this.state.input4}
                      type="number"
                      name="floating_email"
                      id="floating_email"
                      className="w-full max-w-[200px] rounded-lg border border-slate-200 px-2 py-1 hover:border-blue-500 focus:outline-none focus:ring focus:ring-blue-500/40 active:ring active:ring-blue-500/40"
                      placeholder=" "
                      required
                    />
                  </div>
                  &nbsp;&nbsp;
                  <div className="w-1/2  ">
                    <input
                      onChange={(e) => handleChange5(e)}
                      value={this.state.input5}
                      type="number"
                      name="floating_email"
                      id="floating_email"
                      className=" w-full max-w-[200px] rounded-lg border border-slate-200 px-2 py-1 hover:border-blue-500 focus:outline-none focus:ring focus:ring-blue-500/40 active:ring active:ring-blue-500/40"
                      placeholder=" "
                      required
                    />
                  </div>
                </div>

                <br></br>
                <div className="flex flex-wrap  ">
                  <div className="w-full text-left ">
                    &nbsp;&nbsp; 3. Introduce las condiciones climáticas:
                  </div>
                </div>
                <br></br>

                <div className="flex mb-1 ">
                  <div className="w-1/3  ">
                    <label
                      htmlFor="email"
                      className="block mb-2 text-sm font-medium "
                    >
                      Velocidad del viento [km/h]
                    </label>
                  </div>
                  <div className="w-1/3  "></div>
                  <div className="w-1/3  "></div>
                </div>
                <div className="flex mb-1 ">
                  <div className="w-1/3  ">
                    <input
                      onChange={(e) => handleChange6(e)}
                      value={this.state.input6}
                      type="number"
                      name="floating_email"
                      id="floating_email"
                      className="w-full max-w-[200px] rounded-lg border border-slate-200 px-2 py-1 hover:border-blue-500 focus:outline-none focus:ring focus:ring-blue-500/40 active:ring active:ring-blue-500/40"
                      placeholder=" "
                      required
                    />
                  </div>
                  <div className="w-1/3  ">
                    <p className="text-sm ">
                      *Si no conoces la velocidad
                      <br></br>
                      del viento,
                      <a
                        href="https://smn.conagua.gob.mx/tools/GUI/crisis/index.php?kche=1665757343379"
                        target="_blank"
                        className="text-blue-700"
                      >
                        da clic aquí
                      </a>
                    </p>
                  </div>
                  <div className="w-1/3  "></div>
                </div>

                <br></br>

                <div className="flex mb-12">
                  <div className="w-1/3  "></div>
                  <div className="w-1/3  ">
                    <button
                      onClick={shoot}
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                    >
                      Calcular {this.state.selectedValue}
                    </button>
                  </div>
                  <div className="w-1/3   "></div>
                </div>

                <div className={this.state.hidden}>
                  <div className="w-1/2    ">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium  font-black"
                    >
                      {this.state.selectedValue}

                      {this.state.selectedValue == "Tensión de operación"
                        ? " [N]"
                        : " [m]"}
                    </label>
                  </div>

                  <div className="w-1/2   ">
                    <input
                      readOnly
                      value={this.state.input7}
                      type="text"
                      name="floating_email"
                      id="floating_email"
                      className="w-full max-w-[200px] rounded-lg border border-slate-200 px-2 py-1 hover:border-blue-500 focus:outline-none focus:ring focus:ring-blue-500/40 active:ring active:ring-blue-500/40 font-black"
                      placeholder=" "
                      required
                    />
                  </div>
                </div>
                <br></br>
              </div>

              <div id="Span">
                <div className="flex flex-wrap ">
                  <div className="w-full  ">
                    *Nota: La calculadora es una herramienta para elegir un
                    cable, no sustituye los calculos de ingeniería.
                  </div>
                </div>
                <br></br>

                <br></br>
                <div className="flex flex-wrap  " id="1">
                  <div className="w-full text-left ">
                    <b>¿Qué es el SPAN en un tendido de cable?</b> <br></br>
                    Una estructura de tendido compuesta por un cable de peso
                    uniforme sujeto en dos puntos de apoyo A y B forma una curva
                    llamada catenaria, (figura 1). En esta estructura la
                    distancia entre los puntos A y B se conoce como SPAN o VANO.
                  </div>
                </div>
                <br></br>

                <br></br>
                <div className="flex flex-wrap  " id="2">
                  <div className="w-full text-left ">
                    <b>¿Por qué es importante el SPAN?</b> <br></br>
                    El valor máximo de esta distancia (SPAN) es de suma
                    importancia para garantizar que el cable permanezca siempre
                    por debajo de un valor máximo de tensión que garantice su
                    integridad durante todo su tiempo de vida útil y con ello la
                    confiabilidad de operación de la fibra óptica.
                  </div>
                </div>
                <br></br>

                <br></br>
                <div className="flex flex-wrap  " id="3">
                  <div className="w-full text-left ">
                    <b>¿De qué parámetros depende el SPAN?</b> <br></br>
                    Este valor máximo de SPAN depende tanto de valores del
                    propio cable como su peso y su diámetro; de valores de la
                    estructura de tendido como la flecha, la tensión de
                    operación y la diferencia de altura entre los postes; y de
                    las condiciones ambientales como la velocidad del viento.
                  </div>
                </div>
                <br></br>

                <br></br>
                <div className="flex flex-wrap  " id="4">
                  <div className="w-full text-left ">
                    <b>¿Qué es la tensión de operación?</b> <br></br>
                    Es la tensión máxima a la cual opera el cable durante todo
                    el tiempo de su vida útil sin daño a las fibras.
                  </div>
                </div>
                <br></br>

                <br></br>
                <div className="flex flex-wrap  " id="5">
                  <div className="w-full text-left ">
                    <b>¿Qué es la flecha?</b> <br></br>
                    En la estructura de tendido de un cable, la flecha es la
                    distancia desde la horizontal al punto más bajo del cable,
                    figura 1.
                  </div>
                </div>
                <br></br>

                <br></br>
                <div className="flex flex-wrap  " id="6">
                  <div className="w-full text-left ">
                    <b>¿Qué es el desnivel entre postes?</b> <br></br>
                    Es la diferencia de altura entre los postes que sostienen el
                    cable debido a la tipografía del terreno figura 1.
                  </div>
                </div>
                <br></br>

                <br></br>

                <div className="flex flex-wrap  " id="7">
                  <div className="w-full text-left ">
                    <b>¿Qué es la densidad lineal del cable? </b> <br></br>
                    Es el peso del cable por unidad de longitud expresado en
                    kg/m
                  </div>
                </div>
                <br></br>
                <br></br>

                <div className="flex flex-wrap  ">
                  <div className="w-full text-center ">
                    <img
                      id="pagina"
                      className="mx-auto h-auto"
                      src="https://laboratoriodefibraoptica.com/images/calculadora-span.png"
                      alt="Span"
                    />
                    <b> FIGURA 1 </b> <br></br>
                  </div>
                </div>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Span1;
