import React from 'react';
import Utilities from '../utilities/MasterHeader'
import Blogs from '../components/Blogs'
import Footer from '../components/Footer'
const Blog = () => {
    return (
        <div>
            <Utilities />
          
            <Blogs />
            <Footer />
        </div>
    );
};

export default Blog;