import React from 'react';
import Fade from 'react-reveal/Fade';
import { useTranslation } from "react-i18next";
const Philosophy = () => {
    const {t, i18n } = useTranslation();
    return (
        <Fade right>
            <div className="bg-gray-100 flex w-full  pt-8 lg:pt-0 lg:pl-14 p-10 mt-5 lg:mt-0">
                <div className="w-full flex flex-col lg:flex-row lg:pt-10 pb-5">
                    <div className="w-full  flex flex-col justify-center">
                        <div className="flex lg:text-4xl lg:font-bold text-gray-800 self-center lg:pb-10 text-3xl font-bold mb-0 font">{t('gt1')} </div>
                        <div className="flex  flex-col lg:flex-row self-center lg:w-full lg:text-center text-gray-700 ">
                            <div className="w-full p-10 bg-gray-200 m-2 rounded">
                                <div className="font font-semibold text-xl subtitulo-blue mb-10">{t('gt1a')} </div>
                                <div className="font">
                                {t('gb1')} 
                                </div>
                            </div>
                            <div className="w-full p-10 bg-gray-200 m-2 rounded">
                                <div className="font font-semibold text-xl subtitulo-blue mb-10">{t('gt1b')} </div>
                                <div className="font">
                                {t('gb2')} 
                                </div>
                            </div>
                            <div className="w-full p-10 bg-gray-200 m-2 rounded">
                                <div className="font font-semibold text-xl subtitulo-blue mb-10">{t('gt1c')} </div>
                                <div className="font">
                                {t('gb3')} 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fade>
    );
};

export default Philosophy;
