import React from 'react';
import Utilities from '../utilities/MasterHeader'
import Footer from '../components/Footer'
import OrdenServicio from '../components/ordenServicio';
const Orden = () => {
    return (
        <div>
            <Utilities />
            <OrdenServicio />
            <Footer />
        </div>
    );
};

export default Orden;
