import React from 'react';
import Fade from 'react-reveal/Fade';
import Cables from '../assets/images/laboratorioensayos.png'
import Ambientes from '../assets/images/Laboratorioambientea.png'
import Materiales from '../assets/images/wiki.png'
import Optica from '../assets/images/optica.png'
import { useTranslation } from "react-i18next";
const Laboratory_test = () => {
  const {t, i18n } = useTranslation();
    return (
        <Fade right>
            <div className="bg-gray-100 flex w-full  pt-8 lg:pt-0 lg:pl-14 p-10 mt-5 lg:mt-0 lg:pt-10">
                <div className="w-full flex flex-col lg:flex-row">

                    <div className="w-full  flex flex-col justify-center">
                        <div className="flex lg:text-4xl lg:font-bold text-gray-800 self-center lg:pb-0 text-3xl font-bold mb-0 font">{t('pt2')} </div>
                        <div className="flex self-center lg:w-full lg:text-center text-gray-700 font lg:pb-5 lg:pl-20 lg:pr-20">
                            <div className="flex flex-col">


                                      <div className="flex lg:flex-row flex-col w-full ">
                                      <div className="flex flex-col self-start lg:w-full ">
                                        <div className="font-normal lg:text-left  lg:w-auto lg:pt-5" >
                                        <div className="font-semibold text-xl subtitulo-blue lg:text-left lg:pb-10 pb-10 pt-10">{t('pt1')}</div>
                                        {t('pb1')} <br/> <br/>
                                        {t('pb1a')}
                                        </div>
                                      </div>
                                      <div className="lg:visible lg:p-0 mt-0 self-center lg:pt-0 lg:pr-20 lg:mb-10 lg:mt-5 mt-10 ">
                                          <img src={Optica} alt="" className="lg:ml-10 "></img>
                                      </div>
                                    </div>



                                  <div className="flex lg:flex-row flex-col">
                                      <div className="flex flex-col self-center lg:w-full">
                                        <div className="font-normal lg:text-left">
                                        <div className="font-semibold text-xl subtitulo-blue lg:text-left pb-10 pt-20 ">{t('pt3')}</div>
                                        {t('pb2')}

                                        </div>

                                      </div>
                                        <div className=" lg:visible lg:p-0 mt-0 self-center  lg:pt-28 lg:pl-10 lg:mb-10 lg:mt-5 mt-10 ">
                                          {/*  <img src={Cables} alt="" className="lg:ml-10 lg:w-auto"></img> */}
                                        </div>
                                    </div>

                                <div className="flex lg:flex-row flex-col">
                                  <div className="flex flex-col self-center lg:w-full">
                                    <div className="font-normal lg:text-left">
                                    <div className="font-semibold text-xl subtitulo-blue lg:text-left lg:pb-10 pb-5 pt-10">{t('pt4')}</div>
                                    {t('pb3')} 
                                    <div className="font-normal lg:text-left"><strong> {t('pb4')} </strong></div>
                                    <div className="font-normal lg:text-left"><strong> {t('pb5')}</strong></div>
                                    <div className="font-normal lg:text-left"><strong> {t('pb6')}</strong></div>
                                    <div className="font-normal lg:text-left"><strong> {t('pb7')}</strong></div>
                                    </div><br/>
                                    <div className="font-normal lg:text-left">{t('pb18')}</div><br/>
                                    <div className="font-normal lg:text-left">{t('pb19')}</div> 
                                  </div>
                                  <div className=" lg:visible lg:p-0 mt-0 self-center lg:pt-20 lg:pl-10 lg:mb-10 lg:mt-5 mt-10 ">
                                      <img src={Ambientes} alt="" className="lg:ml-10 lg:w-auto"></img>
                                  </div>
                                </div>


                                <div className="flex lg:flex-row flex-col">
                                  <div className="flex flex-col self-center lg:w-full">
                                    <div className="font-normal lg:text-left">
                                    <div className="font-semibold text-xl subtitulo-blue lg:text-left pb-10 pt-10">{t('pt5')} </div>
                                    {t('pb8')}
                                    <div className="font-normal lg:text-left pl-0"> <strong>*</strong> {t('pb10')}</div>
                                    <div className="font-normal lg:text-left pl-0"> <strong>*</strong> {t('pb11')}</div>
                                    <div className="font-normal lg:text-left pl-0"> <strong>*</strong> {t('pb12')}</div>
                                    <div className="font-normal lg:text-left pl-0"> <strong>*</strong> {t('pb13')}</div>
                                    <div className="font-normal lg:text-left pl-0"> <strong>-</strong> {t('pb14')}</div>
                                    <div className="font-normal lg:text-left pl-0"> <strong>-</strong> {t('pb15')}</div>
                                    <div className="font-normal lg:text-left pl-0"> <strong>-</strong> {t('pb16')}</div>
                                    <div className="font-normal lg:text-left pl-0"> <strong>-</strong> {t('pb17')}</div>
                                    </div>
                                  </div>
                                  <div className=" lg:visible lg:p-0 mt-0 self-center lg:pt-20 lg:pl-10 lg:mb-10 lg:mt-5 mt-10 ">
                                      <img src={Materiales} alt="" className="lg:ml-10 lg:w-auto"></img>
                                  </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </Fade>
    );
};

export default Laboratory_test;
