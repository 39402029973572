import React from 'react';
import Fade from 'react-reveal/Fade';
import Cables from '../assets/images/why.PNG'
import { useTranslation } from "react-i18next";
const Why = () => {
    const {t, i18n } = useTranslation();
    return (
        <Fade right>
            <div className="bg-white flex w-full  pt-8 lg:pt-0 lg:pl-14 p-10 mt-5 lg:mt-0">
                <div className="w-full flex flex-col lg:flex-row">
                    <div className="flex flex-col lg:mt-10">
                        <div className="flex lg:text-4xl lg:font-bold text-gray-800 lg:self-center text-2xl md:justify-start md:text-justify lg:pl-20 lg:pb-0  font-bold mt-8 font">{t('st1')} </div>
                        <div className="lg:visible md:hidden w-full self-center lg:pl-20 mt-10 mb-10">
                            <img src={Cables} alt=""></img>
                        </div>
                    </div>
                    <div className="w-full lg:h-auto flex flex-col justify-center self-center ">
                        <div className="flex flex-col text-justify self-center lg:w-full lg:text-center text-gray-700 font text-base mt-0 pb-20 font lg:pl-20 lg:pr-20 lg:text-justify lg:pt-28">
                        <span><strong>FIBERLAB</strong> {t('sb2')}
                        </span>
                        </div>
                    </div>
                </div>
            </div>
        </Fade>
    );
};

export default Why;
