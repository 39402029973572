import React from 'react';
import Fade from 'react-reveal/Fade';
import Cables from '../assets/images/LaboratoryElectronic.PNG'
import { useTranslation } from "react-i18next";
const Laboratory_electronic = () => {
    const {t, i18n } = useTranslation();
    return (
        <Fade right>
            <div className="bg-gray-100 flex w-full  pt-8 lg:pt-0 lg:pl-14 p-10 mt-5 lg:mt-0">
                <div className="lg:h-96 lg:m-10 w-full flex flex-col lg:flex-row">

                    <div className="w-full lg:h-96 flex flex-col justify-center self-center">
                        <div className="flex lg:text-4xl lg:font-bold text-gray-800 self-center lg:self-start lg:pl-10 lg:pb-10 text-3xl font-bold mb-8 font">{t('qt1')} </div>
                        <div className="flex self-start lg:w-auto lg:text-justify lg:p-10 text-gray-700 flex-col lg:pl-10 ">
                        {t('qb1')}
                        <div className="font-normal lg:text-left pl-0"> <strong>* {t('qb2')} </strong></div>
                        <div className="font-normal lg:text-left pl-0"> <strong>* {t('qb3')} </strong></div>
                        <div className="font-normal lg:text-left pl-0"> <strong>* {t('qb4')}</strong></div>
                        <div className="font-normal lg:text-left pl-0"> <strong>* {t('qb5')} </strong></div>

                        </div>
                    </div>
                    <div className="lg:visible md:hidden w-full lg:p-0 mt-10 self-center">
                        <img src={Cables} alt=""></img>
                    </div>
                </div>
            </div>
        </Fade>
    );
};

export default Laboratory_electronic;
