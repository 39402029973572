import React from 'react';
import Header from '../components/Header';
import SubHeader from '../components/SubHeader';

const MasterHeader = () => {
    return (
        <div>
            <Header />
            <div className="h-0 lg:h-10">
                <SubHeader />
            </div>

        </div>
    );
};

export default MasterHeader;
