import React from 'react';
import Fade from 'react-reveal/Fade';
import Cables from '../assets/images/fibra-optica.png'
import { useTranslation } from "react-i18next";
const AEMA = () => {
    const {t, i18n } = useTranslation();
    return (
        <Fade right>
            <div className="bg-gray-100 flex w-full  pt-8 lg:pt-0 lg:pl-14 p-10 mt-5 lg:mt-0">
                <div className="w-full flex flex-col lg:flex-row">
                    <div className="w-full flex flex-col justify-center pt-20 ">
                        <div className="flex lg:text-4xl lg:font-bold text-gray-800 self-center lg:pb-10 text-3xl font-bold mb-8 font">{t('nt1')} </div>
                        <div className=" flex self-center lg:w-full lg:text-center text-gray-700 font lg:pb-20 lg:pl-20 lg:pr-20">
                        {t('nb1')}
                        </div>
                    </div>
                </div>
            </div>
        </Fade>
    );
};

export default AEMA;
