import React from 'react';
import Fade from 'react-reveal/Fade';
import Cables from '../assets/images/QuienesSomos.png'
import { useTranslation } from "react-i18next";
const How = () => {
    const {t, i18n } = useTranslation();
    return (
        <Fade right>
            <div className="flex bg-white w-full h-68  pt-8 lg:pt-0 lg:pl-14 p-10 mt-10">
                <div className="w-full flex flex-col lg:flex-row ">
                    <div className="w-full lg:h-96 flex flex-col justify-center self-center">
                        <div className="flex lg:text-4xl lg:font-bold subtitulo self-center lg:pb-0 text-3xl font-bold mb-10 font"></div>
                        <div className="flex self-center lg:w-96 lg:text-center font">
                            {t("hb1")}
                        </div>
                    </div>
                    <div className=" lg:visible w-full self-center">
                    <img src={Cables} alt=""></img>
                    </div>
                </div>
            </div>
        </Fade>
    );
};

export default How;
