import React, { Suspense } from 'react'; //nuevo el suspense
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import i18n from './i18n';


ReactDOM.render(
  <Suspense fallback="">
  <React.StrictMode>
    <App />
  </React.StrictMode>
  </Suspense>,
  document.getElementById('root')
);

