import React from 'react';
import Utilities from '../utilities/MasterHeader'

import Footer from '../components/Footer'
import Encuestas from '../components/encuesta'

const Encuesta = () => {
    return (
        <div>
            <Utilities />
            <Encuestas />

            <Footer />
        </div>
    );
};

export default Encuesta;
