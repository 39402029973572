import React from 'react';
import Utilities from '../utilities/MasterHeader'
import Footer from '../components/Footer'
import Mensaje from '../components/mensaje'

const Gracias = () => {
    return (
        <div>
            <Utilities />
            <Mensaje />

            <Footer />
        </div>
    );
};

export default Gracias;
