/*

------------------------------------------------------------------------------------------------
🆁🅼🅽 2022 07 01

------------------------------------------------------------------------------------------------
*/
import React, { useState, useRef } from "react";
import useInterval from "../hooks/useTimeout.js";
import emailjs from "emailjs-com";
import { useTranslation } from "react-i18next";
import Constantes from "./Constantes";

const Encuestas = () => {
  let url_str = window.location;
  let url = new URL(url_str);
  let search_params = url.searchParams; 
  
  let id = search_params.get('id');

  const { t, i18n } = useTranslation();
  const form = useRef();

  const [calidad, setCalidad] = useState("");
  const [ids] =useState(id);

  const onOptionChange = (e) => {
    setCalidad(e.target.value);
  };
  const [tiempo, setTiempo] = useState("");

  const onOptionChange2 = (e) => {
    setTiempo(e.target.value);
  };
  const [atencion, setAtencion] = useState("");

  const onOptionChange3 = (e) => {
    setAtencion(e.target.value);
  };
  const [equipos, setEquipos] = useState("");

  const onOptionChange4 = (e) => {
    setEquipos(e.target.value);
  };

  const [mensaje, setMensaje] = useState("");
  const [politica, setPolitica] = useState(false);
  const [error, setError] = useState("");
  const [label, setLabel] = useState("Enviar");
  const [interval, setInterval] = useState(0);

  const [color, setColor] = useState(
    "bg-blue-400 items-center hover:bg-blue-400  text-center flex lg:justify-center font-bold pr-20"
  );
  const [bg, setBg] = useState(
    "h-screen bg-black flex flex-col justify-center p-60"
  );
  useInterval(() => {
    setColor(
      "p-2 bg-blue-300 mt-5 text-white rounded w-40 self-center hover:bg-blue-500 "
    );
    setLabel("Enviar");
    setBg("h-screen bg-black flex flex-col justify-center p-60");
  }, interval);
  const enviar = (e) => {
    e.preventDefault();
    console.log(politica);
    if (politica == false) {
      setColor(
        "p-2 bg-red-700 mt-5 text-white rounded w-full self-center hover:bg-blue-300"
      );
      setLabel("Revisa el Encuesta, hay campos vacios");
      setInterval(7000);
    } else {
      emailjs
        .sendForm(
          "service_2jwm465",
          "template_a9uk745",
          form.current,
          "qU9BAxfSmOSGpQvYY"
        )
        .then(
          (result) => {
              // Codificar nuestro videojuego como JSON
  /* 
              const cargaUtil = JSON.stringify({
                id: 1,
                nombre: "rmsn",
                precio: "5.00",
                calificacion: 8,
              }); */

             const cargaUtil = JSON.stringify({
              id: id,
              pre1: calidad,
              pre2: tiempo,
              pre3: atencion,
              pre4: equipos,
              comen: mensaje,

            });

          /*   console.log(cargaUtil); */
            // ¡Y enviarlo!
            fetch(`${Constantes.RUTA_API}/actualizar_videojuego.php`, {
              method: "PUT",
              body: cargaUtil,
            })
              .then((res) => res.json())
              .catch((error) => console.error("Error:", error))
              .then(function(response) {

                if(response == true) {
                  window.location = "/Gracias"


                } else {
                  console.log('Error !!!');
                }
              }) 
             
            /*   respuesta.json();
        
              console.log(respuesta); */

          },
          (error) => {
            console.log(error.text);
          }
        );
    }
    //console.log(nombre.target.value)
  };
  return (
    <div className="flex bg-white w-full h-68  pt-8 lg:pt-0 lg:pl-14 p-10 mt-10 ">
      <div className="w-full flex flex-col lg:flex-row ">
        <div className="w-full lg:h-auto flex flex-col justify-center self-center ">
          <div className=" flex flex-col self-center lg:w-full lg:text-center font text-gray-700  lg:pl-96 lg:pr-96 lg:pb-20 rounded bg-gray-50 ">
            <div className="flex lg:text-4xl text-gray-700 self-center lg:pb-10 text-3xl font-bold mb-10 font pt-5 ">
           
            <span class="box-decoration-clone bg-gradient-to-r from-blue-300 to-blue-300 text-white px-3 ...">
            {t('RMN1')}
  </span>
           
            </div>
          
            <h3 className=" lg:text-1.5xl text-gray-700  font-bold  ">  {t('RMN2')}</h3>
            <div className="flex flex-col mt-10 sx:pl-28 sx:pr-28">
              <label className="font text-left pb-2 text-gray-900">
                {error}
              </label>
              <form ref={form} onSubmit={enviar} className="flex flex-col">
                <label className="font text-left pb-2 text-gray-900 pl-2 lg:pl-0"></label>

                <div className="App ">
                  <h3 className=" lg:text-1.5xl text-gray-700  font-bold  "> {t('RMN3')}</h3>
                  &nbsp;&nbsp;
      
                  <input
                    type="radio"
                    name="calidad"
                    value="Excelente"
                    id="excelente"
                    checked={calidad === "Excelente"}
                    onChange={onOptionChange}
                  />
                  &nbsp;&nbsp;
                  <label htmlFor="excelente" className=" lg:text-1.5xl text-gray-500   ">{t('RMN12')}</label>
                  &nbsp;&nbsp;
                  &nbsp;&nbsp;
                  <input
                    type="radio"
                    name="calidad"
                    value="Muy bien"
                    id="muy bien"
                    checked={calidad === "Muy bien"}
                    onChange={onOptionChange}
                  />
                  &nbsp;&nbsp;
                  <label htmlFor="muy bien" className=" lg:text-1.5xl text-gray-500   ">{t('RMN11')}</label>
                  &nbsp;&nbsp;
                  &nbsp;&nbsp;
                  <input
                    type="radio"
                    name="calidad"
                    value="Bueno"
                    id="bueno"
                    checked={calidad === "Bueno"}
                    onChange={onOptionChange}
                  />
                  &nbsp;&nbsp;
                  <label htmlFor="bueno" className=" lg:text-1.5xl text-gray-500   "> {t('RMN10')}</label>
                  &nbsp;&nbsp;
                  &nbsp;&nbsp; 
                  <input
                    type="radio"
                    name="calidad"
                    value="Regular"
                    id="regular"
                    checked={calidad === "Regular"}
                    onChange={onOptionChange}
                  />
                  &nbsp;&nbsp;
                  <label htmlFor="regular" className=" lg:text-1.5xl text-gray-500   ">Regular</label>
                  &nbsp;&nbsp;
                  &nbsp;&nbsp;
                  <input
                    type="radio"
                    name="calidad"
                    value="Malo"
                    id="malo"
                    checked={calidad === "Malo"}
                    onChange={onOptionChange}
                  />
                  &nbsp;&nbsp;
                  <label htmlFor="malo" className=" lg:text-1.5xl text-gray-500   ">{t('RMN14')}</label>
                  <br></br>
                  <br></br>
                  <h3 className=" lg:text-1.5xl text-gray-700  font-bold  " >{t('RMN4')}</h3>
                  <input
                    type="radio"
                    name="tiempo"
                    value="Excelente"
                    id="excelente"
                    checked={tiempo === "Excelente"}
                    onChange={onOptionChange2}
                  />
                  &nbsp;&nbsp;
                  <label htmlFor="excelente" className=" lg:text-1.5xl text-gray-500   ">{t('RMN12')}</label>
                  &nbsp;&nbsp;
                  &nbsp;&nbsp;
                  <input
                    type="radio"
                    name="tiempo"
                    value="Muy bien"
                    id="muy bien"
                    checked={tiempo === "Muy bien"}
                    onChange={onOptionChange2}
                  />
                  &nbsp;&nbsp;
                  <label htmlFor="muy bien" className=" lg:text-1.5xl text-gray-500   ">{t('RMN11')}</label>
                  &nbsp;&nbsp;
                  &nbsp;&nbsp;
                  <input
                    type="radio"
                    name="tiempo"
                    value="Bueno"
                    id="bueno"
                    checked={tiempo === "Bueno"}
                    onChange={onOptionChange2}
                  />
                  &nbsp;&nbsp;
                  <label htmlFor="bueno" className=" lg:text-1.5xl text-gray-500   "> {t('RMN10')}</label>
                  &nbsp;&nbsp;
                  &nbsp;&nbsp; 
                  <input
                    type="radio"
                    name="tiempo"
                    value="Regular"
                    id="regular"
                    checked={tiempo === "Regular"}
                    onChange={onOptionChange2}
                  />
                  &nbsp;&nbsp;
                  <label htmlFor="regular" className=" lg:text-1.5xl text-gray-500   ">Regular</label>
                  &nbsp;&nbsp;
                  &nbsp;&nbsp;
                  <input
                    type="radio"
                    name="tiempo"
                    value="Malo"
                    id="malo"
                    checked={tiempo === "Malo"}
                    onChange={onOptionChange2}
                  />
                  &nbsp;&nbsp;
                  <label htmlFor="malo" className=" lg:text-1.5xl text-gray-500   ">{t('RMN14')}</label>
                  <br></br>
                  <br></br>
                  <h3 className=" lg:text-1.5xl text-gray-700  font-bold  " >{t('RMN5')}</h3>
                  <input
                    type="radio"
                    name="atencion"
                    value="Excelente"
                    id="excelente"
                    checked={atencion === "Excelente"}
                    onChange={onOptionChange3}
                  />
                  &nbsp;&nbsp;
                  <label htmlFor="excelente" className=" lg:text-1.5xl text-gray-500   ">{t('RMN12')}</label>
                  &nbsp;&nbsp;
                  &nbsp;&nbsp;
                  <input
                    type="radio"
                    name="atencion"
                    value="Muy bien"
                    id="muy bien"
                    checked={atencion === "Muy bien"}
                    onChange={onOptionChange3}
                  />
                  &nbsp;&nbsp;
                  <label htmlFor="muy bien" className=" lg:text-1.5xl text-gray-500   ">{t('RMN11')}</label>
                  &nbsp;&nbsp;
                  &nbsp;&nbsp;
                  <input
                    type="radio"
                    name="atencion"
                    value="Bueno"
                    id="bueno"
                    checked={atencion === "Bueno"}
                    onChange={onOptionChange3}
                  />
                  &nbsp;&nbsp;
                  <label htmlFor="bueno" className=" lg:text-1.5xl text-gray-500   "> {t('RMN10')}</label>
                  &nbsp;&nbsp;
                  &nbsp;&nbsp; 
                  <input
                    type="radio"
                    name="atencion"
                    value="Regular"
                    id="regular"
                    checked={atencion === "Regular"}
                    onChange={onOptionChange3}
                  />
                  &nbsp;&nbsp;
                  <label htmlFor="regular" className=" lg:text-1.5xl text-gray-500   ">Regular</label>
                  &nbsp;&nbsp;
                  &nbsp;&nbsp;
                  <input
                    type="radio"
                    name="atencion"
                    value="Malo"
                    id="malo"
                    checked={atencion === "Malo"}
                    onChange={onOptionChange3}
                  />
                  &nbsp;&nbsp;
                  <label htmlFor="malo" className=" lg:text-1.5xl text-gray-500   ">{t('RMN14')}</label>
                  <br></br>
                  <br></br>
                  <h3 className=" lg:text-1.5xl text-gray-700  font-bold  "> {t('RMN6')}</h3>
                  <input
                    type="radio"
                    name="equipos"
                    value="Si"
                    id="si"
                    checked={equipos === "Si"}
                    onChange={onOptionChange4}
                  />
                  &nbsp;&nbsp;
                  <label htmlFor="si" className=" lg:text-1.5xl text-gray-500   ">{t('RMN15')}</label>
                  &nbsp;&nbsp;
                  &nbsp;&nbsp;
                  &nbsp;&nbsp;
                  <input
                    type="radio"
                    name="equipos"
                    value="No"
                    id="no"
                    checked={equipos === "No"}
                    onChange={onOptionChange4}
                  />
                  &nbsp;&nbsp;
                  <label htmlFor="no " className=" lg:text-1.5xl text-gray-500   ">No</label>
                  &nbsp;&nbsp;
                 
                  <br></br>
                  <br></br>
                </div>
                <label className=" lg:text-1.5xl text-gray-700  font-bold  "> {t('RMN7')}</label>
                
                <textarea
                  col="5"
                  id="mensaje"
                  name="mensaje"
                  className="textarea ml-2 mr-2 lg:mr-0 lg:ml-0 border border-gray-700 "
                  onChange={(e) => setMensaje(e.target.value)}
                  value={mensaje}
                  required
                ></textarea>
             
      
            {/*   <input type="hidden" name="id" value={ contract  } 
                       /> */}
                <div className="flex flex-row mt-5 text-sm pl-2 lg:p-0 lg:text-base">
                  <input
                    type="checkbox"
                    id="aviso"
                    name="aviso"
                    className="self-center"
                    onChange={(e) => setPolitica(e.target.checked)}
                  />
                  <label for="aviso" className="font pl-2 self-center lg:text-1.5xl text-gray-700  font-bold ">
                    * {t("ab7")}
                  </label>
                </div>
                <input type="hidden" name="id" id="id"   value={ids}/>
                <button className={color} onClick={enviar}>
                {t('RMN9')}
                </button>
                <div className="font mt-10 text-sm p-2 lg:text-base lg:p-0">
                  {t("ab9")}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Encuestas;
