import React from 'react';
import Fade from 'react-reveal/Fade';
import { useTranslation } from "react-i18next";
const Centre = () => {
    const {t, i18n } = useTranslation();
    return (
        <Fade right>
            <div className="bg-gray-100 flex w-full  pt-8 lg:pt-0 lg:pl-14 p-10 mt-5 lg:mt-0 lg:p-20">
                <div className="w-full flex flex-col lg:flex-row">
                    <div className="w-full flex flex-col justify-center">
                        <div className="flex lg:text-4xl lg:font-bold text-gray-800 self-center lg:pb-10 text-2xl font-bold mb-8 font pt-10">{t('vt1')} </div>
                        <div className="flex flex-col self-center lg:w-full lg:text-center text-gray-700 font">
                        <div className="self-center w-80 lg:w-96 pl-10 pr-10">
                          <div className="font-normal lg:text-left font text-justify"> <strong></strong> {t('vb1')} </div>
                          <div className="font font-normal lg:text-left font text-justify"> <strong>-</strong> {t('vb2')}</div>
                          <div className="font font-normal lg:text-left font text-justify"> <strong>-</strong> {t('vb3')}</div>
                          <div className="font font-normal lg:text-left font text-left"> <strong>-</strong> {t('vb4')}.</div>
                          <div className="font font-normal lg:text-left font text-left"> <strong>-</strong> {t('vb5')}</div>
                        </div>
                        <div className="flex lg:flex-row flex-col justify-center pt-5 pb-5">
                            <div className="lg:text-center p-10 rounded font-semibold subtitulo-blue text-xl bg-gray-200 m-2 ">01.- {t('vb6')}</div>
                            <div className="lg:text-center p-10 rounded font-semibold subtitulo-blue text-xl bg-gray-200 m-2 ">02.- {t('vb7')}</div>
                            <div className="lg:text-center p-10 rounded font-semibold subtitulo-blue text-xl bg-gray-200 m-2 ">03.- {t('vb8')}</div>
                            <div className="lg:text-center p-10 rounded font-semibold subtitulo-blue text-xl bg-gray-200 m-2 ">04.- {t('vb9')}</div>
                        </div>
                        <div className="lg:text-center font-normal lg:text-left font text-justify lg:w-full w-80 pl-10 pr-10">{t('vb10')}</div>
                        </div>
                    </div>
                </div>
            </div>
        </Fade>
    );
};

export default Centre;
