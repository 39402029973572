import {BrowserRouter as Router, Route } from 'react-router-dom'
import Home from '../pages/Home'
import Empresas from '../pages/Empresa'
import Acreditaciones from '../pages/Acreditaciones'
import Laboratorio from '../pages/Laboratorio'
import Servicios from '../pages/Servicios'
import Blog from '../pages/Blog'
import Wiki from '../pages/wiki'
import Media from '../pages/Media'
import Contacto from '../pages/contact'
import Encuesta from '../pages/Encuesta'
import Gracias from '../pages/Gracias'
import Descripcion from '../pages/Descripcion'
import Span from '../pages/Span'
import Orden from '../pages/Orden'
import Mensaje from '../pages/MensajeOrden'


function Application(params) {
    return(
        <Router>
            <Route exact path="/">
                <Home />
            </Route>
            <Route exact path="/empresa">
                <Empresas />
            </Route>
            <Route exact path="/acreditaciones">
                <Acreditaciones />
            </Route>
            <Route exact path="/laboratorio">
                <Laboratorio />
            </Route>
            <Route exact path="/servicios">
                <Servicios />
            </Route>
            <Route exact path="/blog">
                <Blog />
            </Route>
            <Route exact path="/media">
                <Media />
            </Route>
            <Route exact path="/wiki">
                <Wiki />
            </Route>
            <Route exact path="/contacto">
                <Contacto />
            </Route>
            <Route exact path="/encuesta">
                <Encuesta />
            </Route>
             <Route exact path="/gracias">
                <Gracias />
            </Route>
            <Route exact path="/Descripcion">
                <Descripcion />
            </Route>
            <Route exact path="/Span">
                <Span />
            </Route>
            <Route exact path="/Orden">
                <Orden />
            </Route>
            <Route exact path="/Mensaje">
                <Mensaje />
            </Route>
        </Router>
    )
}

export default Application;
