import React from 'react';
import Fade from 'react-reveal/Fade';
import Cables from '../assets/images/LaboratoryCalibration.PNG'
import { useTranslation } from "react-i18next";
const Laboratory_calibration = () => {
    const {t, i18n } = useTranslation();
    return (
        <Fade right>
            <div className="bg-white flex w-full pt-8 lg:pt-0 lg:pl-14 p-10 mt-5 lg:mt-0">
                <div className="lg:h-96 lg:m-10 w-full flex flex-col lg:flex-row">
                    <div className="lg:visible w-full lg:p-5 mb-10 self-center">
                        <img src={Cables} alt="" className="lg:w-96 lg:ml-20"></img>
                    </div>
                    <div className="w-full  flex flex-col justify-center">
                        <div className="flex lg:text-4xl lg:font-bold text-gray-800 self-center lg:pb-10 text-3xl font-bold mb-8 font">{t('rt1')} </div>
                        <div className="flex self-center lg:w-auto lg:text-left text-gray-700 font lg:text-justify">
                        {t('rb1')}
                        </div>
                    </div>
                </div>
            </div>
        </Fade>
    );
};

export default Laboratory_calibration;
